import React, { useEffect, useState } from "react";

import "./dish.video.profile.scss";
import DishMutedWidget from "../mutedWidget/mutedWidget.component";

interface ProfileProps {
  name?: string;
  isDeviceProfile?: boolean;
  hasToShowIcon?: boolean;
  className?: string;
  showSettings?: boolean;
  setShowSettings?: (show: boolean) => void;
}

export function DishVideoProfile(props: ProfileProps) {
  useEffect(() => {
    if (props.isDeviceProfile !== undefined && !props.isDeviceProfile) {
      localStorage.setItem('audio_status', 'false');
      localStorage.setItem('video_status', 'false');
    }
  }, []);

  return (

    <div className={`${props.className ?? ''} DishVideoProfile ${props.isDeviceProfile ? 'DishVideoComponent' : ''}`}>
      <div className="title">
        <span>{props.name}</span>
        {
          props.hasToShowIcon && <i
            className="las la-cog"
            onClick={() => props.setShowSettings && props.setShowSettings(!props.showSettings)}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '0px',
              right: '0px',
              margin: '10px',
              bottom: 'initial',
              background: 'white',
              padding: '10px',
              borderRadius: '50%',
              color: 'rgba(23, 44, 84, 0.89)',
              fontSize: '1.5rem',
              zIndex: '999'}}
            ></i>
        }
      </div>
      {
        props.isDeviceProfile && <DishMutedWidget />
      }
    </div>

  );
}

export default DishVideoProfile;

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState, FC } from "react";

// TODO (@rafael.popescu): This file contains many React components and hooks
// that are not directly related to the EditProfileAppInfo component. It should be
// refactored into a separate file and folder structure.

// Types
interface UpdateStatus {
  status: "success" | "error" | "loading";
  message: string;
}

interface UpdateProgress {
  downloading: boolean;
  progress: number;
  completed: boolean;
}

interface UpdateResponse {
  status: string;
  error?: string;
  version?: string;
}

// Custom hooks
const useAppVersion = () => {
  const [appVersion, setAppVersion] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAppVersion = useCallback(async () => {
    try {
      setIsLoading(true);
      // @ts-ignore
      const version = await window.electron?.getAppVersion();
      setAppVersion(version || null);
      setError(null);
    } catch (err: any) {
      console.error("Failed to get app version:", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (window.electron) {
      fetchAppVersion();
    }
  }, [fetchAppVersion]);

  return { appVersion, isLoading, error, fetchAppVersion };
};

const useUpdateManager = () => {
  const { t } = useTranslation();
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus | null>(null);
  const [isChecking, setIsChecking] = useState(false);
  const [isUpdaterAvailable, setIsUpdaterAvailable] = useState(false);
  const [downloadVersion, setDownloadVersion] = useState<string | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const clearUpdateStatus = useCallback((seconds = 10) => {
    return setTimeout(() => setUpdateStatus(null), seconds * 1000);
  }, []);

  const resetUpdateState = useCallback(() => {
    setIsDownloading(false);
    setDownloadProgress(0);
    setDownloadVersion(null);
  }, []);

  useEffect(() => {
    if (
      // @ts-ignore
      window.electron &&
      // @ts-ignore
      window.electron.startAutoUpdate &&
      // @ts-ignore
      window.electron.getUpdateProgress
    ) {
      setIsUpdaterAvailable(true);
    }
  }, []);

  const trackDownloadProgress = useCallback(
    (version: string) => {
      setIsDownloading(true);
      setDownloadVersion(version);
      setDownloadProgress(0);

      const intervalId = setInterval(async () => {
        try {
          const progress: UpdateProgress =
            // @ts-ignore
            await window.electron.getUpdateProgress();

          const progressValue =
            typeof progress.progress === "number" && !isNaN(progress.progress)
              ? Math.round(progress.progress)
              : 0;

          setDownloadProgress(progressValue);

          if (progress.completed && !progress.downloading) {
            clearInterval(intervalId);
            setUpdateStatus({
              status: "success",
              message: t("form.updater.progress", {
                version,
                progress: 100,
              }),
            });
            resetUpdateState();
            clearUpdateStatus();

            // NOTE (@rafael.popescu): We can launch a modal commponent to ask for installation
            // window.electron.installUpdate();

          } else {
            setUpdateStatus({
              status: "success",
              message: t("form.updater.progress", {
                version,
                progress: progressValue,
              }),
            });
          }
        } catch (error) {
          clearInterval(intervalId);
          resetUpdateState();
          setUpdateStatus({
            status: "error",
            message: t("form.updater.failed"),
          });
          clearUpdateStatus();
        }
      }, 500); // Check more frequently for smoother updates

      return () => clearInterval(intervalId);
    },
    [t, clearUpdateStatus, resetUpdateState]
  );

  const checkForUpdates = useCallback(async () => {
    if (isChecking) return;

    setIsChecking(true);
    setUpdateStatus({ status: "loading", message: t("form.updater.checking") });

    try {
      // @ts-ignore
      const res: UpdateResponse = await window.electron.startAutoUpdate();

      if (!res) {
        setUpdateStatus({ status: "error", message: t("form.updater.failed") });
        clearUpdateStatus();
        return;
      }

      if (res.status === "already-updated") {
        setUpdateStatus({
          status: "success",
          message: t("form.updater.up-to-date"),
        });
        clearUpdateStatus();
        return;
      }

      if (res.error?.includes("Not Found")) {
        setUpdateStatus({
          status: "success",
          message: t("form.updater.not-available"),
        });
        clearUpdateStatus();
        return;
      }

      if (res.status === "no-download-available") {
        setUpdateStatus({
          status: "success",
          message: t("form.updater.no-download-available"),
        });
        clearUpdateStatus();
        return;
      }

      if (res.status === "downloading-started" && res.version) {
        setUpdateStatus({
          status: "success",
          message: t("form.updater.progress", {
            version: res.version,
            progress: 0,
          }),
        });
        return trackDownloadProgress(res.version);
      }
    } catch (error) {
      console.error("Update check failed:", error);
      setUpdateStatus({ status: "error", message: t("form.updater.failed") });
      clearUpdateStatus();
    } finally {
      setIsChecking(false);
    }
  }, [t, isChecking, clearUpdateStatus, trackDownloadProgress]);

  return {
    updateStatus,
    isChecking,
    isUpdaterAvailable,
    downloadProgress,
    isDownloading,
    downloadVersion,
    checkForUpdates,
  };
};

// Sub-components
const AppVersionDisplay: FC<{ appVersion: string | null }> = ({
  appVersion,
}) => {
  const { t } = useTranslation();

  return (
    <div className="AppInfo">
      <div className="AppInfoItem">
        <ul>
          <li>
            {t("edit.profile.app-info.version")}:{" "}
            {appVersion || t("common.loading")}
          </li>
        </ul>
      </div>
    </div>
  );
};

const UpdateButton: FC<{
  isChecking: boolean;
  onCheckUpdate: () => void;
}> = ({ isChecking, onCheckUpdate }) => {
  const { t } = useTranslation();

  return (
    <button className="Submit" onClick={onCheckUpdate} disabled={isChecking}>
      {isChecking ? t("form.updater.checking") : t("form.updater.check.title")}
    </button>
  );
};

const UpdateStatusDisplay: FC<{
  updateStatus: UpdateStatus | null;
}> = ({ updateStatus }) => {
  if (!updateStatus) return null;

  return (
    <div className={`Status ${updateStatus.status}`}>
      <p>{updateStatus.message}</p>
    </div>
  );
};

// Main component
export function EditProfileAppInfo(): JSX.Element {
  const { appVersion } = useAppVersion();
  const { updateStatus, isChecking, isUpdaterAvailable, checkForUpdates } =
    useUpdateManager();

  return (
    <div className="EditProfileAppInfoComponent">
      <AppVersionDisplay appVersion={appVersion} />

      {isUpdaterAvailable && (
        <div className="AppUpdater">
          <UpdateButton
            isChecking={isChecking}
            onCheckUpdate={checkForUpdates}
          />
          <UpdateStatusDisplay updateStatus={updateStatus} />
        </div>
      )}
    </div>
  );
}

const dev = (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development');
const preprod = process.env.REACT_APP_ENV === 'preproduction';

const domain = dev ? 'https://sippar4.enovait.biz' : preprod ? 'https://internalsippar.com' : 'https://telemedicinacomitas.com';
const apiDomain = dev ? 'https://sippar4.enovait.biz' : preprod ? 'https://internalsippar.com' : 'https://telemedicinacomitas.com';

export const environment = {
    brickter: {
        name: 'comitas',
    },
    production: !dev && !preprod,
    debug: dev,
    cheeky: {
        host: domain,
    },
    dish: {
        host: domain,
        iceServers: [
            { urls: (dev || preprod) ? "stun:telemedicinacomitas.com:3478" : "stun:telemedicinacomitas.com:3478" },
            { urls: (dev || preprod) ? "turn:telemedicinacomitas.com:3478?transport=udp" : "turn:telemedicinacomitas.com:3478?transport=udp", username: 'enovait', credential: 'password' },
        ]
    },
    api: {
        host: apiDomain,
    },
};

// Modules
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

// Selfie Segmentation
var selfieSegmentation: any;

/**
 * Dish Background Video
 * @description Class to add a background to a video
 * @param {MediaStream} stream
 * @param {HTMLImageElement} background
 */
export class DishBackgroundVideo {
  stream: MediaStream;
  background: HTMLImageElement;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  video: HTMLVideoElement;
  selfieSegmentation: SelfieSegmentation;
  isRunning: boolean = false;
  intervalId: number | null = null;

  constructor({
    stream,
    background,
  }: {
    stream: MediaStream;
    background: HTMLImageElement;
  }) {
    // save stream and background
    this.stream = stream;
    this.background = background;

    let width = stream.getVideoTracks()[0].getSettings().width || 1280;
    let height = stream.getVideoTracks()[0].getSettings().height || 720;

    if (width > 1280) width = 1280;
    if (height > 720) height = 720;

    // create canvas
    this.canvas = document.createElement("canvas");
    this.canvas.width = width;
    this.canvas.height = height;

    // get context
    const context = this.canvas.getContext("2d");
    if (!context) throw new Error("Canvas context not found");
    this.context = context;

    // Improve quality of the image
    this.context.imageSmoothingEnabled = true;
    this.context.imageSmoothingQuality = "high";

    // create video
    this.video = document.createElement("video");
    this.video.width = width;
    this.video.height = height;
    this.video.muted = true;
    this.video.srcObject = stream;
    this.video.autoplay = true;

    // Importante: Evitamos que el navegador suspenda el video cuando está en background
    this.video.playsInline = true;
    this.video.setAttribute("disablePictureInPicture", "true");

    // add video to body
    document.body.appendChild(this.video);

    // set video style
    this.video.style.position = "fixed";
    this.video.style.right = "0";
    this.video.style.top = "0";
    this.video.style.width = `${width}px`;
    this.video.style.height = `${height}px`;
    this.video.style.zIndex = "-9999";
    this.video.style.opacity = "0";
    this.video.style.pointerEvents = "none";

    // selfie segmentation
    selfieSegmentation = this.selfieSegmentation = new SelfieSegmentation({
      locateFile: (file: any) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });

    // set options
    this.selfieSegmentation.setOptions({
      modelSelection: 1,
    });

    // detect stream stop
    this.video.addEventListener("ended", () => {});

    // detect stream stop
    this.selfieSegmentation.onResults((results: any) => {
      this.onResults(results);
    });

    // Monitorear cambios de visibilidad
    document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
  }

  handleVisibilityChange() {
    if (this.isRunning) {
      if (document.hidden) {
        this.stopAnimationFrame();
        this.startInterval();

        // Forzar que el video siga reproduciéndose
        const playPromise = this.video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.log("Background video play prevented:", error);
          });
        }
      } else {
        this.stopInterval();
        this.startAnimationFrame();
      }
    }
  }

  onResults(results: any) {
    // clear canvas
    this.context.drawImage(
      results.segmentationMask,
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );

    // Only overwrite existing pixels.
    this.context.globalCompositeOperation = "source-out";

    // draw background
    if (this.background.width) {
      const canvasAspect = this.canvas.width / this.canvas.height;
      const imageAspect = this.background.width / this.background.height;

      let renderHeight = this.canvas.height;
      let renderWidth = this.canvas.width;
      let x = 0;
      let y = 0;

      if (canvasAspect > imageAspect) {
        renderHeight = this.canvas.width / imageAspect;
        y = (this.canvas.height - renderHeight) / 2;
      } else {
        renderWidth = this.canvas.height * imageAspect;
        x = (this.canvas.width - renderWidth) / 2;
      }

      this.context.drawImage(
        this.background,
        0,
        0,
        this.background.width,
        this.background.height,
        x,
        y,
        renderWidth,
        renderHeight
      );
    }

    // Only overwrite missing pixels.
    this.context.globalCompositeOperation = "destination-atop";

    // draw image
    this.context.drawImage(
      results.image,
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );

    // Only overwrite missing pixels.
    this.context.restore();
  }

  private isSelfieSegmentationClosed = false;

  async processFrame() {
    if (!this.video.width || this.isSelfieSegmentationClosed) return;

    try {
      await this.selfieSegmentation.send({ image: this.video });
    } catch (error) {
      console.log("Error processing frame, selfie segmentation may be closed:", error);
      // Si ocurre un error, podemos detener el procesamiento para evitar errores en cascada
      this.isRunning = false;
    }
  }

  startAnimationFrame() {
    const FPS = 24;
    const frameInterval = 1000 / FPS; // Time between frames in ms
    let lastFrameTime = 0;
    let animationFrameId: number;

    const animateFrame = async (timestamp: number) => {
      if (!this.isRunning) return;

      // Calculate time since last frame
      const elapsed = timestamp - lastFrameTime;

      // Skip frame if not enough time has passed
      if (elapsed >= frameInterval) {
        // Process frame and update timestamp
        lastFrameTime = timestamp;
        await this.processFrame();
      }

      animationFrameId = requestAnimationFrame(animateFrame);
    };

    animationFrameId = requestAnimationFrame(animateFrame);
  }

  startInterval() {
    // Usar un intervalo más lento cuando está en background para ahorrar recursos
    const backgroundFPS = 10;
    this.intervalId = window.setInterval(async () => {
      await this.processFrame();
    }, 1000 / backgroundFPS) as unknown as number;
  }

  stopAnimationFrame() {
    // Nada que detener específicamente ya que requestAnimationFrame se manejará
    // por la condición this.isRunning en animateFrame
  }

  stopInterval() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  start() {
    console.log(
      `Canvas width: ${this.canvas.width}, height: ${this.canvas.height}`
    );
    console.log(
      `Video width: ${this.video.width}, height: ${this.video.height}`
    );

    this.isRunning = true;

    // Iniciar con el método apropiado según la visibilidad actual
    if (document.hidden) {
      this.startInterval();
    } else {
      this.startAnimationFrame();
    }
  }

  stop() {
    this.isRunning = false;
    this.stopAnimationFrame();
    this.stopInterval();

    // Eliminar event listener de visibilidad
    document.removeEventListener("visibilitychange", this.handleVisibilityChange.bind(this));

    // Evitar cerrar la selfie segmentation más de una vez
    if (!this.isSelfieSegmentationClosed) {
      this.isSelfieSegmentationClosed = true;

      try {
        // Cerrar una sola instancia, no ambas
        this.selfieSegmentation.close();

        // No cerrar la variable global si es la misma instancia
        if (selfieSegmentation !== this.selfieSegmentation && selfieSegmentation) {
          selfieSegmentation = null;
        }
      } catch (error) {
        console.log("Error closing selfie segmentation:", error);
      }
    }
  }

  run() {
    // start
    setTimeout(async () => {
      this.start();
    }, 1000);
  }

  async getStream() {
    // get stream
    const streamResult = this.canvas.captureStream(24);

    // add audio
    const [audioTrack] = this.stream.getAudioTracks();

    // add audio track
    if (audioTrack) streamResult.addTrack(audioTrack);

    // return stream
    return streamResult;
  }
}

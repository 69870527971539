// Types
import { useTranslation } from 'react-i18next';
import { AvailableType } from '../../dish.types';

// Properties
import { ParticipantsProperties } from './participants.properties';

// Styles
import './participants.styles.scss';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { UserType } from '../../../../types/user.type';
import axios from 'axios';
import { environment } from '../../../../environment';
import { toast } from 'react-toastify';

export type ParticipantsType = {
  id: string;
  name: string;
  surname: string;
  email: string;
  smile?: boolean;
  color?: string;
  isAvailable?: boolean;
  isDeleted?: boolean;
  clientId?: string;
  clientName?: string;
  name_surname: string;
}

interface GroupedUsers {
  [clientName: string]: Array<{
    id: string;
    name: string;
    surname: string;
    name_surname: string;
    email: string;
    smile?: boolean;
    color?: string;
    isAvailable?: boolean;
    isDeleted?: boolean;
    clientId?: string;
    clientName?: string
  }>;
}

/**
 * Participants Component
 * @description Component to render participants of videocall
 * @param {ParticipantsProperties} properties
 * @returns {JSX.Element}
 */
export function ParticipantsComponent(properties: ParticipantsProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // Array of all users in the database for all user groups
    const [users, setUsers] = useState<ParticipantsType[]>([])

    // State to know the active client
    const [activeClient, setActiveClient] = useState<string | undefined | null>(null);

    // State to know the search term
    const [searchTerm, setSearchTerm] = useState<string>("");

    // Names for the groups
    const inCallName = t('participants.inCall');
    const noClientName = t('participants.noClient');

    /**
     * Get all users that are in the user groups
     */
    const getUsers = async () => {
      const groupsIds = properties.user.users_groups.map(user_group => user_group.group.id).join(',');

      try {
        const response = await axios.get(`${environment.api.host}/api/user`, {
          headers: {
            Authorization: `Bearer ${properties.user.access_token}`
          },
          params: {
            groups: groupsIds
          }
        });

        if (!response.data.items) {
          setUsers([]);
          return;
        }

        // Filter users that are deleted or don't have access
        response.data.items = response.data.items.filter((user: UserType) => !user.is_deleted && user.access);

        // Map users to participants
        const userParticipants: ParticipantsType[] = response.data.items.map((user: UserType) => {
          return {
            id: user.id,
            name: user.name,
            surname: user.surnames,
            email: user.email,
            smile: false,
            color: 'gray',
            isAvailable: false,
            isDeleted: user.is_deleted,
            clientId: user.clientId,
            clientName: user.clientName,
            name_surname: `${user.name} ${user.surnames}`
          } as ParticipantsType;
        })
        .filter((user: ParticipantsType) => user.id !== properties.user.id);

        if (properties.participants) {
          // Add participants to the users
          const participants = Object.values(properties.participants);

          participants.forEach(participant => {
            const user = userParticipants.find(user => user.id === participant.user.id);

            if (!user) {
              userParticipants.push({
                id: participant.user.id,
                name: participant.user.name,
                surname: participant.user.surnames,
                email: participant.user.email,
                smile: true,
                color: 'orange',
                isAvailable: false,
                clientId: participant.user.clientId,
                clientName: participant.user.clientName,
                name_surname: `${participant.user.name} ${participant.user.surnames}`,
              } as ParticipantsType);
            }
          });
        }

        setUsers(userParticipants);

      } catch (error) {
        console.error('[Login]: Error getting clients from API', error);

        setUsers([]);
      }
    }

    useEffect(() => {
      getUsers();
    }, [properties.participants]);

    // call to user
    const callUser = (available: AvailableType) => {
      if (properties.isCalling) {
        toast('Ya estás llamando a alguien');
        return;
      }

      const usersClicked = properties.clickedUsers.map(user => user.userId);

      if (usersClicked.includes(available.user.id)) return;

      properties.setIsCalling(true);

      if (available.color !== 'green') return;

      const newClickedUsers = [...properties.clickedUsers, { userId: available.user.id, socketId: available.socketId, username: `${available.user.name} ${available.user.surnames}` }];

      console.log('call:to', {
        available: available,
        room: properties.room,
      });

      properties.setClickedUser(newClickedUsers);

      properties.socket.emit('call:to', {
          available: available,
          room: properties.room,
      })
    }

    const cancelCall = (available: AvailableType) => {
      properties.setIsCalling(false);

      // Delete user from clicked users
      properties.setClickedUser(properties.clickedUsers.filter(user => user.userId !== available.user.id));

      properties.socket.emit('call:cancel', {
        available: available,
        room: properties.room,
      });
    }

    useEffect(() => {
      if (properties.rejectedCall) {
        properties.setClickedUser(properties.clickedUsers.filter(user => user.userId !== properties.rejectedCall));
        properties.setUserRejectedCall('');
        properties.setIsCalling(false);
      }
    }, [properties.rejectedCall]);

    useEffect(() => {
      if (properties.acceptedCall) {
        properties.setClickedUser(properties.clickedUsers.filter(user => user.userId !== properties.acceptedCall));
        properties.setUserAcceptedCall('');
        properties.setIsCalling(false);
      }
    }, [properties.acceptedCall]);

    // render user
    const renderUser = (participant: ParticipantsType) => {
      const clickedUser = properties.clickedUsers.find(user => user.userId === participant.id);

      return <Fragment key={participant.id}>
          <i
            className={`${participant.smile ? 'las la-smile' : !participant.isAvailable ? 'las la-phone disconnected' : `las la-phone ${clickedUser ? 'end' : ''}`}`}
          />
          <div className="user">
              <div className="name">{participant.name} {participant.surname}</div>
          </div>
          <span style={{ backgroundColor: participant.color || 'green', }} />
      </Fragment>
    }

  // Group users by client name
  const groupdUsersMemo = useMemo(() => {
    const groups = users.reduce<GroupedUsers>((groups, user) => {
      let isParticipant = false;

      // Check if user is a participant in the call
      Object.values(properties.participants).forEach(participant => {
        if (participant.user.id === user.id) { // If the user is a participant then add it to the inCall group
          if (!groups[inCallName]) {
            groups[inCallName] = [];
          }
          groups[inCallName].push({ // Add user to the inCall group with the corresponding properties
            ...user,
            smile: true,
            color: 'orange',
            isAvailable: false
          });
          isParticipant = true; // Set isParticipant to true
        }
      });

      if (!isParticipant) { // If the user is not a participant then add it to the client group
        const clientName = user.clientName || noClientName;
        if (!groups[clientName]) {
          groups[clientName] = [];
        }
        const available = properties.availables.find(av => av.user?.id === user.id);
        groups[clientName].push({
          ...user,
          smile: available ? available.color === 'orange' : user.isAvailable,
          color: available ? available.color : 'gray',
          isAvailable: available && available.color === 'orange' ? true : (available ? true : false)
        });
      }

      // Sort groups by name
      Object.keys(groups).sort().forEach(key => {
        const sorted = Object.keys(groups).reduce<GroupedUsers>((obj, k) => {
          obj[k] = groups[k];
          return obj;
        }, {});
        groups = sorted;
      });

      // Assert that inCall is the first group
      if (groups[inCallName]) {
        const inCall = groups[inCallName];
        delete groups[inCallName];
        groups = {
          [inCallName]: inCall,
          ...groups
        };
      }

      setActiveClient(Object.keys(groups)[0]);

      return groups;
    }, {});

    Object.keys(groups).forEach(clientName => {
      groups[clientName].sort((a, b) => {
        if (a.isAvailable && !b.isAvailable) {
          return -1;
        }
        if (!a.isAvailable && b.isAvailable) {
          return 1;
        }
        return 0;
      });
    });

    return groups;
  }, [users, properties.participants, properties.availables]);

  const toggleAccordion = (clientName: string) => {
    setActiveClient(activeClient === clientName ? null : clientName);
    setSearchTerm('');
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const normalizeString = (str: string) => {
    return str
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .toLowerCase();
  };

  const filteredUsers = (clientName: string) => {
    return groupdUsersMemo[clientName].filter(user =>
      normalizeString(user.name_surname).includes(normalizeString(searchTerm))
    );
  };

  return (
    <div className="ParticipantsComponent Modal">
        <div className='Header'>
            {t('participants.title')}
            <i className='las la-times last' onClick={() => { properties.setScene(undefined) }}></i>
        </div>
        <div className="accordion">
            {Object.keys(groupdUsersMemo).map(clientName => (
                <div key={clientName} className={`accordion-item ${activeClient === clientName ? 'active' : ''}`}>
                    <div className="accordion-header" onClick={() => toggleAccordion(clientName)}>
                        {clientName}
                        <i className={`las ${activeClient === clientName ? 'la-angle-up' : 'la-angle-down'}`}></i>
                    </div>
                    <div className="accordion-content">
                       {clientName !== inCallName && (
                        <div className='Input'>
                        <i className='las la-search'></i>
                        <input
                           type="text"
                           placeholder={t('participants.search').toString()}
                           value={searchTerm}
                           onChange={handleSearch}
                         />
                        </div>
                        )}
                        <ul>
                          {filteredUsers(clientName).map(user => (
                            <li key={user.id} onClick={() => {
                              const available = properties.availables.find(av => av.user?.id === user.id);
                              if (!available || user.smile) {
                                return;
                              }

                              const clickedUser = properties.clickedUsers.find(user => user.userId === available.user.id);

                              if (clickedUser) {
                                cancelCall(available);
                                return;
                              }
                              callUser(available);
                            }}>
                              {renderUser(user)}
                            </li>
                          ))}
                          {filteredUsers(clientName).length === 0 && (
                            <li className='NoUsers'>{t('participants.noUsers')}</li>
                          )}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
}

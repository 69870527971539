// Modules
import axios from "axios";
import { useEffect, useState } from "react";

// Environment
import { environment } from "../../../../environment";

// Components
import { DishResize } from "../room/resize/dish.layout";

// Types
import { SelectorMediaProperties } from "./selectorMedia.properties";
import { SelectorMediaAppType } from "./selectorMedia.types";
import { useTranslation } from "react-i18next";
import { Id, toast } from "react-toastify";

import './selectorMedia.styles.scss';

/**
 * Selector Media Component
 * @param {SelectorMediaProperties} properties
 * @returns {JSX.Element}
 */
export function SelectorMediaComponent(properties: SelectorMediaProperties): JSX.Element {

    // translation function
    const { t } = useTranslation();

    // state to avoid multiple clicks in media selector
    const [selected, setSelected] = useState(false)

    // array of devices
    const [apps, setApp] = useState<SelectorMediaAppType[]>([]);

    // get apps from backend
    const getApps = async () => {
      const groups = properties.groupsData;

      let apps: SelectorMediaAppType[] = [];

      let promises: Promise<void>[] = [];

      if (Array.isArray(groups) && groups.length > 0) {
        groups.forEach((group: any) => {
          group.groups_apps && group.groups_apps.forEach((app: any) => {
            promises.push(
              (async () => {
                // @ts-ignore
                const isAppInstalled = await window.electron.isAppAvailable(app.app.url);

                console.log('APP', app.app.name, 'is installed', isAppInstalled);

                if (!apps.find((a) => a.name === app.app.name)) {
                  let isAppActive = false;

                  // @ts-ignore
                  const sources = await window.electron.getSources();
                  sources.forEach((source: SelectorMediaAppType) => {
                    if (source.name === app.app.selector) {
                      isAppActive = true;
                    }
                  });

                  const appIsOnArray = apps.find((a) => a.name === app.app.name);

                  if (appIsOnArray) {
                    appIsOnArray.active = isAppActive;
                  } else {
                    apps.push({
                      ...app.app,
                      active: isAppActive,
                      isInstalled: isAppInstalled,
                    });
                  }
                }
              })()
            );
          });
        });

        Promise.all(promises).then(() => {
          apps.sort((a, b) => a.name.localeCompare(b.name));
          setApp(apps);
          properties.setHasMediaBeenFetched(true);
        });
      }
    }

    useEffect(() => {
        getApps();
    }, [properties.groupsData]);

    const connectNativeSource = (path: string, name: string, appName: string) => {

        const toastLoadingId = toast.loading(t('media.selector.wait', { appName: appName }), {
            autoClose: false,
            isLoading: true
        })

        // @ts-ignore
        if (window.electron) {

            // capture all screen for apps without window name
            if (name === 'Entire Screen') {

                connectSource(name, 0, toastLoadingId, appName, () => {
                    // @ts-ignore
                    window.electron.abrirPrograma(path)

                })

            }

            // capture only app window
            else {
                // @ts-ignore
                window.electron.abrirPrograma(path)
                connectSource(name, 0, toastLoadingId, appName, () => {
                    toast.update(toastLoadingId, {
                        type: toast.TYPE.SUCCESS,
                        render: t('media.selector.success', { appName: appName }),
                        autoClose: 5000,
                        isLoading: false
                    });
                });

            }

        }

    }

    const disconnectNativeSource = (path: string, name: string, appName: string) => {
      // @ts-ignore
      window.electron.cerrarPrograma(path)
      toast(t('media.selector.disconnected', { appName: appName }),{
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const stream = properties.appsBeingUsed.find((m) => m.name === appName);
      const streamId = stream?.stream.id;

      properties.setAppsBeingUsed(properties.appsBeingUsed.filter((m) => m.name !== appName));

      properties.removeStream?.(properties.localScreams.findIndex((m) => m.id === streamId));
    }

    // connect to native source
    const connectSource = async (name: string, times: number, toastId: Id, appName: string, callBack?: () => void) => {

        // @ts-ignore
        window.electron.getSources().then(async (sources: any) => {

            // @ts-ignore
            const app = sources.find((source: any) => source.name === name)

            if (app) {
                try {

                    // get media stream
                    const media = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            // @ts-ignore
                            mandatory: {
                                chromeMediaSource: 'desktop',
                                chromeMediaSourceId: app.id,
                                minWidth: 1280,
                                maxWidth: 1920,
                                minHeight: 720,
                                maxHeight: 1080
                            }
                        }
                    })

                    const isOnBeingUsed = properties.appsBeingUsed.find((m) => m.name === appName);

                    if (!isOnBeingUsed) {
                      properties.setAppsBeingUsed([...properties.appsBeingUsed, { name: appName, stream: media }]);
                    }

                    // connect stream
                    properties.addStream?.(media);
                    callBack?.()

                }
                catch (e) {

                    console.log('error mediaDevices', e)

                }

            }
            else {

                if (times < 25) {
                    // try to found app again (apps sometimes are slow to open)
                    setTimeout(() => {

                        connectSource(name, times + 1, toastId, appName, function() {
                            toast.update(toastId, {
                                type: toast.TYPE.SUCCESS,
                                render: t('media.selector.success', { appName: appName }),
                                autoClose: 5000,
                                isLoading: false
                            });
                        })

                    }, 1000)
                } else {
                    setSelected(false)
                    toast.update(toastId, {
                        type: toast.TYPE.ERROR,
                        render: t('media.selector.fail', { appName: appName }),
                        autoClose: 5000,
                        isLoading: false
                    });
                }

            }

        })

    }

    const [showModal, setShowModal] = useState(false);
    const [manualContent, setManualContent] = useState({ content: '', name: '' });
    const [appToLaunch, setAppToLaunch] = useState<SelectorMediaAppType | undefined>(undefined);

    const fetchManualContent = async (manualId: string) => {
        const response = await axios.get(`${environment.api.host}/api/manuals/${manualId}`, {
            headers: {
                Authorization: `Bearer ${properties.user.access_token}`
            }
        });

        return response.data;
    }

    const launchApp = async (app: SelectorMediaAppType) => {
      if (properties.appsBeingUsed.find(m => m.name === app.name)) {
          disconnectNativeSource(app.url, app.selector, app.name);
          setSelected(false);
          return;
      } else {
          connectNativeSource(app.url, app.selector, app.name);
      }

      new DishResize('DishScenary', 'DishScenary', 'DishVideoComponent').resize();
      properties.setScene?.(undefined);
    };

    const handleAppClick = async (app: SelectorMediaAppType) => {
      if (selected) return;
      setSelected(true);

      if (app.manual_id) {
          // Fetch the manual content based on manual_id
          const manual = await fetchManualContent(app.manual_id);
          properties.setIsShowingHelp(true);

          setManualContent(manual);
          setAppToLaunch(app);
          setShowModal(true);
          return;
      }

      launchApp(app);
    };

    const handleCloseModal = () => {
      setShowModal(false);
      properties.setIsShowingHelp(false);
      if (appToLaunch) {
          launchApp(appToLaunch);
          setAppToLaunch(undefined);
      }
    };

    return <>
        {
          properties.canBeDisplayed && apps.map((app, index) => {
              // hide apps without electron
              // @ts-ignore
              if (app.url && !window.electron) return <></>

              return app.isInstalled && <div
                  className="camera"
                  style={{
                    backgroundColor: properties.appsBeingUsed.find(m => m.name === app.name) ? '#b7bffc' : '#fff',
                    borderStyle: 'solid',
                    borderColor: properties.appsBeingUsed.find(m => m.name === app.name) ? '#2f49ff' : '#fff',
                }}
                  key={app.id} onClick={() => handleAppClick(app)}>
                  {
                      // hide add icon if device is used
                      <i className={properties.appsBeingUsed.find(m => m.name === app.name) ? 'las la-times' : 'las la-plus'}></i>
                  }
                  <div className="name">{app.name}</div>
                  <div className="model">{app.model}</div>
                  <div className="image" style={{
                      backgroundImage: app.icon ? `url(${app.icon})` : 'unset',
                      }} />
              </div>
          })
        }
        {
            showModal && <div className='HelpView'>
            <div className='HelpContainer'>
              <div className='Header'>
                <div className='Title'>{manualContent.name}</div>
                <div className="Btn-container">
                  <div className='Close-Btn' onClick={() => handleCloseModal()}>
                    { appToLaunch && properties.appsBeingUsed.find(m => m.name === appToLaunch.name) ? t('media.selector.close-app') : t('media.selector.open-app') }
                  </div>
                  <div className='Close-Btn circle' onClick={() => {
                    setShowModal(false)
                    properties.setIsShowingHelp(false)
                    setAppToLaunch(undefined)
                    properties.setScene?.(undefined)
                  }}>
                    <i className='las la-times'></i>
                  </div>
                </div>
              </div>
              <div className='Body'>
                <div dangerouslySetInnerHTML={{ __html: manualContent.content }} />
              </div>
            </div>
          </div>
        }
    </>
}

import { useEffect, useState } from 'react';
import './userInfo.styles.scss';
import { useTranslation } from 'react-i18next';
import { get } from 'http';


export interface UserInfoComponentProps {
  name: string;
  setShowLogout: (showLogout: boolean) => void;
  setConfigOption: (configOption: string) => void;
  setScene: (scene: string | undefined) => void;
}

export function UserInfoComponent(properties: UserInfoComponentProps) {
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);
  const [isNetworkAppInstalled, setNetworkAppInstalled] = useState<boolean>(false);

  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const isAppInstalled = async () => {
    // @ts-ignore
    if (window.electron) {
      // @ts-ignore
      const networkAppInstalled = await window.electron.isAppAvailable('%USERPROFILE%\\\\Desktop\\\\ComitasRedes.lnk');
      setNetworkAppInstalled(networkAppInstalled);
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (window.electron) {
      // @ts-ignore
      isAppInstalled();
    }
  }, []);

  return (
    <>
      <div className="UserInfoComponent" onClick={() => {
        toggleMenu();
        properties.setScene(undefined);
      }}>
        <div className='userInfo'>
          {properties.name} <i className="las la-user"></i>
        </div>
        {isMenuVisible && (
          <div className='dropdown-menu'>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene(undefined);

                setTimeout(() => {
                  properties.setScene('edit-profile');
                  properties.setConfigOption('name');
                }, 100);
              }
            }>
              <i className="las la-user"></i>
              <span>{t('dropdown-menu.edit-profile')}</span>
            </div>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene(undefined);

                setTimeout(() => {
                  properties.setScene('edit-profile');
                  properties.setConfigOption('password');
                }, 100);
              }
            }>
              <i className="las la-lock"></i>
              <span>{t('dropdown-menu.change-password')}</span>
            </div>
            <div className='dropdown-item' onClick={
              () => {
                properties.setScene(undefined);

                setTimeout(() => {
                  properties.setScene('edit-profile');
                  properties.setConfigOption('other-options');
                }, 100);
              }
            }>
              <i className="las la-cog"></i>
              <span>{t('dropdown-menu.user-settings')}</span>
            </div>
            <div className='dropdown-item logout' onClick={() => {
              properties.setScene(undefined);
              properties.setShowLogout(true)
              }}>
              <i className="las la-power-off"></i>
              <span>{t('dropdown-menu.logout')}</span>
            </div>
            {
              // @ts-ignore
              window.electron &&
              <>
              <hr style={
                  {
                    width: '100%',
                    color: '#1b3f7c',
                  }
                }/>
              </>
            }
            {
              // @ts-ignore
              window.electron && isNetworkAppInstalled &&
              <>
                <div className='dropdown-item' onClick={() => {
                  // @ts-ignore
                  window.electron.openConnApp();
                }}>
                  <i className="las la-satellite-dish"></i>
                  <span>{t('edit.profile.app-network')}</span>
                </div>
              </>
            }
            {
              // @ts-ignore
              window.electron &&
              <div className='dropdown-item' onClick={
                () => {
                  properties.setScene(undefined);

                  setTimeout(() => {
                    properties.setScene('edit-profile');
                    properties.setConfigOption('app-info');
                  }, 100);
                }
              }>
                <i className="las la-info-circle"></i>
                <span>{t('edit.profile.app-info')}</span>
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
}

export const translations = {
    resources: {
        es: {
            translation: {
              // ========== Login Texts ==========
                "login.title.start": "Inicia sesión en ",
                "login.title.end": "con tu email y contraseña",
                "login.email": "Email",
                "login.password": "Contraseña",
                "login.submit": "Iniciar Sesión",
                "login.alternative": "o también puedes crear una cuenta",
                "login.register": "Registrarse",
                "login.forgot": "¿Olvidaste tu contraseña?",

                // ========== Forgo Texts ==========
                "forgot.title": "Recuperar Contraseña",
                "forgot.email": "Email",
                "forgot.submit": "Enviar",
                "forgot.login": "Volver a Iniciar Sesión",

                // ========== Restore Texts ==========
                "restore.title": "Restablecer Contraseña",
                "restore.password": "Nueva contraseña",
                "restore.repeatPassword": "Repetir Contraseña",
                "restore.submit": "Restablecer Contraseña",
                "restore.passwordsEmpty": "Las contraseñas no pueden estar vacías",
                "restore.passwordsDontMatch": "Las contraseñas no coinciden",

                // ========== Change Password Texts ==========
                "change-password.title": "Establecer Contraseña",
                "change-password.message": "Introduce tu contraseña de acceso",
                "change-password.password": "Contraseña",
                "change-password.repeatPassword": "Repetir Contraseña",
                "change-password.passwordsEmpty": "Las contraseñas no pueden estar vacías",
                "change-password.passwordsDontMatch": "Las contraseñas no coinciden",
                "change-password.submit": "Establecer Contraseña",

                // ========== Register Texts ==========
                "register.title.start": "Regístrate en ",
                "register.title.end": "y accede a todos los servicios",
                "register.success": " Registro completado correctamente. El equipo administrativo de Sippar se pondrá en contacto contigo para activar tu cuenta",
                "register.success.alternative": "Una vez activada tu cuenta podrás <br /> acceder a todos los servicios de <b>Sippar</b>",
                "register.login": "Iniciar Sesión",
                "register.invitation": "Código de invitación",
                "register.name": "Nombre",
                "register.surname": "Apellidos",
                "register.email": "Email",
                "register.submit": "Registrarse",
                "register.password": "Contraseña",
                "register.repeatPassword": "Repetir Contraseña",
                "register.legal": "Acepto",
                "register.conditions": "las condiciones",
                "register.alternative": "o si ya tienes una cuenta",
                "register.password.info": "La contraseña debe contener:",
                "register.password.info.length": "Al menos 8 caracteres",
                "register.password.info.uppercase": "Al menos una letra mayúscula",
                "register.password.info.lowercase": "Al menos una letra minúscula",
                "register.password.info.number": "Al menos un número",
                "register.password.info.special": "Al menos un carácter especial",

                // ========== Schedule Texts ==========
                "schedule.title": "Teleconsultas",
                "schedule.add": "AÑADIR",
                "schedule.jumpstep": "SALTAR PASO",
                "schedule.future": "Próximas",
                "schedule.past": "Pasadas",
                "schedule.search": "Buscar...",
                "schedule.empty": "No hay resultados",
                "schedule.delete.title": "Eliminar teleconsulta",
                "schedule.delete.success": "La teleconsulta ha sido eliminada",
                "schedule.delete.information": "Solamente el autor de la cita puede eliminarla. Una vez eliminada la información no se podrá recuperar, aunque ",
                "schedule.delete.information2": " cuenta con guardado perpetuo de la información para la seguridad de los datos",
                "schedule.delete.submit": "Eliminar teleconsulta",
                "schedule.insert.back": "Volver",
                "schedule.insert.success": "La teleconsulta se ha creado correctamente",
                "schedule.insert.subject": "Asunto",
                "schedule.insert.start": "Inicio",
                "schedule.insert.end": "Final",
                "schedule.insert.length": "Duración",
                "schedule.insert.info": "Rellene los siguientes campos para crear una teleconsulta:",
                "schedule.insert.info.subject": "El asunto debe tener un mínimo de 5 caracteres y un máximo de 500",
                "schedule.insert.info.date": "La fecha y hora de inicio debe ser posterior a la fecha y hora actuales",
                "schedule.insert.info.length": "La duración mínima es de 10 minutos y la máxima de 3 horas",
                "schedule.insert.submit": "Crear teleconsulta",
                "schedule.select.patient.title": "Selecciona el Paciente",
                "schedule.select.patient.search": "Buscar...",
                "schedule.select.patient.empty": "No hay resultados",
                "schedule.select.search": "Buscar...",
                "schedule.select.title": "Participantes",
                "schedule.select.error": "Sucedió un error al cargar los participantes de la teleconsulta",
                "schedule.select.errorbtn": "Volver a intentar",
                "schedule.update.back": "Volver",
                "schedule.update.participants": "Participantes",
                "schedule.update.success": "Teleconsulta actualizada",
                "schedule.update.subject": "Asunto",
                "schedule.update.start": "Inicio",
                "schedule.update.end": "Final",
                "schedule.update.submit": "GUARDAR",

                // ========== Directory Texts ==========
                "directory.title": "Pacientes",
                "directory.add": "AÑADIR",
                "directory.search": "Buscar...",
                "directory.empty": "No hay resultados",
                "directory.delete.title": "Eliminar Paciente",
                "directory.delete.success": "Paciente eliminado",
                "directory.delete.information": "Solamente los autor del paciente puede eliminar la ficha del paciente. Una vez eliminada la información no se podrá recuperar, aunque <b>Sippar</b> cuenta con guardado perpetuo de la información para la seguridad de los datos",
                "directory.delete.submit": "Eliminar Paciente",
                "directory.insert.title": "Nuevo Paciente",
                "directory.insert.success": "El paciente se ha creado correctamente",
                "directory.insert.name": "Nombre",
                "directory.insert.surname": "Apellidos",
                "directory.insert.document": "Identificación",
                "directory.insert.information": "Detalles Personales",
                "directory.insert.born": "Fecha de Nacimiento",
                "directory.insert.occupation": "Ocupación",
                "directory.insert.observations": "Observaciones",
                "directory.insert.history": "Historial Médico",
                "directory.insert.history.date": "Fecha",
                "directory.insert.history.type": "Tipo de Apunte",
                "directory.insert.history.type.1": "Telemática",
                "directory.insert.history.type.2": "Presencial",
                "directory.insert.history.type.3": "Revisión de Historial",
                "directory.insert.history.type.4": "Análisis de Resultados",
                "directory.insert.history.delete": "Borrar Entrada",
                "directory.insert.history.add": "Añadir Entrada",
                "directory.insert.address": "Dirección",
                "directory.insert.street": "Calle",
                "directory.insert.city": "Ciudad",
                "directory.insert.zip": "Código Postal",
                "directory.insert.country": "País",
                "directory.insert.contact": "Contacto",
                "directory.insert.email": "Email",
                "directory.insert.phone": "Teléfono",
                "directory.insert.phisic": "Detalles Fisiológicos",
                "directory.insert.genre": "Género",
                "directory.insert.genre.1": "Hombre",
                "directory.insert.genre.2": "Mujer",
                "directory.insert.genre.3": "Otro",
                "directory.insert.height": "Altura",
                "directory.insert.height.placeholder": "Medida en cm",
                "directory.insert.size": "Peso",
                "directory.insert.size.placeholder": "Medida en kg",
                "directory.insert.submit": "Crear Paciente",
                "directory.update.title": "Editar Paciente",
                "directory.update.success": "Modificado correctamente",
                "directory.update.name": "Nombre",
                "directory.update.surname": "Apellidos",
                "directory.update.document": "Identificación",
                "directory.update.information": "Detalles Personales",
                "directory.update.born": "Fecha de Nacimiento",
                "directory.update.occupation": "Ocupación",
                "directory.update.observations": "Observaciones",
                "directory.update.history": "Historial Médico",
                "directory.update.history.date": "Fecha",
                "directory.update.history.type": "Tipo de Apunte",
                "directory.update.history.type.1": "Telemática",
                "directory.update.history.type.2": "Presencial",
                "directory.update.history.type.3": "Revisión de Historial",
                "directory.update.history.type.4": "Análisis de Resultados",
                "directory.update.history.delete": "Borrar Entrada",
                "directory.update.history.add": "Añadir Entrada",
                "directory.update.address": "Dirección",
                "directory.update.street": "Calle",
                "directory.update.city": "Ciudad",
                "directory.update.zip": "Código Postal",
                "directory.update.country": "País",
                "directory.update.contact": "Contacto",
                "directory.update.email": "Email",
                "directory.update.phone": "Teléfono",
                "directory.update.phisic": "Detalles Fisiológicos",
                "directory.update.genre": "Género",
                "directory.update.genre.1": "Hombre",
                "directory.update.genre.2": "Mujer",
                "directory.update.genre.3": "Otro",
                "directory.update.height": "Altura",
                "directory.update.height.placeholder": "Medida en cm",
                "directory.update.size": "Peso",
                "directory.update.size.placeholder": "Medida en kg",
                "directory.update.submit": "Editar Paciente",

                // ========== Call Texts ==========
                "call.title": "Llamada",
                "call.message": "Has recibido una solicitud para unirte a una videoconferencia. Acepta la llamada y accede a la emisión",
                "call.accept": "Aceptar",
                "call.reject": "Rechazar",
                "call.rec.info": "La siguiente llamada va a ser grabada",
                "call.auto": "La llamada se iniciará automáticamente en",

                // ========== Controls Texts ==========
                "controls.speed": "Velocidad",
                "controls.gallery": "Galería",
                "controls.share.stop": "Dejar de presentar",
                "controls.share.another": "Presentar otra cosa",
                "controls.focus": "Focus",

                // ========== Creator Texts ==========
                "creator.title": "Iniciar teleconsulta",
                "creator.subtitle": "Crea una teleconsulta e invita al resto de participantes. Podrás participar con múltiples dispositivos conectados",
                "creator.start": "Llamar",
                "creator.video": "Exploración local",
                "creator.users.start": "Hay",
                "creator.users.middle": "usuarios",
                "creator.users.end": "disponibles",
                "creator.micro.selector": "Selección de microfono",
                "creator.micro.no_devices": "Dispositivo de voz no encontrado",
                "creator.micro.placeholder": "Seleccione un canal",
                "creator.headphone.selector": "Selección de auriculares",
                "creator.headphone.no_devices": "Dispositivo de audio no encontrado",
                "creator.headphone.placeholder": "Seleccione un canal",
                "creator.video.selector": "Selección de cámara",
                "creator.video.no_devices": "Dispositivo de video no encontrado",
                "creator.video.placeholder": "Seleccione un canal",
                "creator.background.selector": "Selección de fondo",
                "creator.update": "ACTUALIZAR",

                // ========== Device Texts ==========
                "info.device.not.available": "No se pudo obtener el dispositivo de audio/video por defecto, por favor verifique que el dispositivo este conectado y no este siendo usado por otra aplicación",
                "warn.device.audio.not.valid": "El dispositivo '{{ label }}' de audio seleccionado no puede ser utilizado como dispositivo de audio de entrada. Por favor, seleccione otro dispositivo",
                "warn.device.video.not.valid": "El dispositivo '{{ label }}' de video seleccionado no puede ser utilizado como dispositivo de video de entrada. Por favor, seleccione otro dispositivo",

                // ========== Media Texts ==========
                "media.selector.fail": "No se puede localizar la aplicación {{appName}}, por favor, asegúrese de que la aplicación esté abierta y vuelva a intentarlo",
                "media.selector.wait": "Esperando a la aplicación de {{appName}}...",
                "media.selector.success": "Compartiendo {{appName}}",
                "media.selector.disconnecting": "Desconectando de {{appName}}...",
                "media.selector.disconnected": "Aplicación {{ appName }} desconectada",
                "media.selector.open-app": "Abrir aplicación",
                "media.selector.close-app": "Cerrar aplicación",

                // ========== Error Texts ==========
                "error.title": "Sin conexión",
                "error.description": "No se pudo conectar con el servidor. Por favor, revise su conexión a internet.",
                "error.button": "Volver a intentar",

                // ========== Force Texts ==========
                "force.title": "¿Quiere cerrar sesión?",
                "force.description": "No se puede iniciar sesión en dos dispositivos al mismo tiempo. Si desea iniciar sesión en este dispositivo, debe cerrar sesión en el otro dispositivo",
                "force.yes": " Utilizar este dispositivo",
                "force.no": "Cerrar sesión",

                // ========== Participant Texts ==========
                "participants.title": "Participantes",
                "participants.actives": "Activos",
                "participants.availables": "Disponibles",
                "participants.empty": "No hay participantes",
                "participants.allUsers": "Todos los usuarios",
                "participants.inCall": "En la llamada",
                "participants.noClient": "Sin cliente",
                "participants.search": "Buscar usuario...",
                "participants.noUsers": "No hay usuarios disponibles con el nombre introducido",

                // ========== Chat Texts ==========
                "dish.chat.form.placeholder": "Escribe un mensaje a todos...",

                // ========== Selector share Texts ==========
                "selector.empty": "",
                "selector.share.title": "Compartir",
                "selector.share.subtitle": "Pantalla",

                // ========== Settings Texts ==========
                "settings.stream-info.title": "Información del Stream",
                "settings.status": "Estado de Stream",
                "settings.audio.placeholder": "Cambiar canal de audio",
                "settings.video.placeholder": "Cambiar canal de vídeo",
                "settings.background.placeholder": "Cambiar fondo de vídeo",
                "settings.background.delete": "Quitar fondo",
                "settings.title": "Configuración",
                "settings.video": "Estado del Video",
                "settings.rate": "Frecuencia de fotogramas",
                "settings.aspect": "Relación de aspecto",
                "settings.size": "Tamaño",
                "settings.audio": "Estado del Audio",
                "settings.muted": "Volumen",
                "settings.noiseSuppression": "Supresión de ruido",
                "settings.sampleRate": "Frecuencia de muestreo",
                "settings.sampleSize": "Tamaño de muestra",
                "settings.echoCancellation": "Cancelación de eco",
                "settings.latency": "Latencia",
                "settings.autoGainControl": "Control automático de ganancia",
                "settings.stream": "Estado del Stream",
                "settings.enabled": "Estado del Stream",
                "settings.readyState": "Estado de la conexión",
                "settings.kind": "Tipo de Stream",
                "settings.name": "Nombre",
                "settings.audio.selector": "Puedes seleccionar el canal de salida de audio de tu dispositivo, para que puedas escuchar la llamada en el dispositivo que desees",
                "settings.audioInput.selector": "Puedes seleccionar el canal de entrada de audio de tu dispositivo, para que puedas compartir el micrófono que desees",
                "settings.video.selector": "Puedes seleccionar el canal de entrada de video de tu dispositivo, para que puedas compartir la cámara que desees",
                "settings.background.selector": "Puedes seleccionar el fondo que desees compartir en la llamada, para que puedas personalizar tu entorno",

                // ========== Button Texts ==========
                "button.video.screenshot.title": "Hacer una caputra",
                "button.video.toggle.title": "Activar/Desactivar video",
                "button.video.audio.toggle.title": "Activar/Desactivar audio",
                "button.video.ptz.control.title": "Mostar/Ocultar controles PTZ",
                "button.video.ptz.flip.title": "Girar la cámara PTZ",
                "button.video.status.title": "Configuración",
                "button.video.settings.title": "Configuración",
                "button.calendar.title": "Programar una teleconsulta en el calendario",
                "button.notes.title": "Gestionar pacientes",
                "button.info.title": "Información de conexión",
                "button.share.title": "Compartir equipos médicos y aplicaciones",
                "button.directShare.title": "Compartir pantalla",
                "button.participants.title": "Listado de participantes",
                "button.chat.title": "Chat",
                "button.edit.title": "Editar perfil",
                "button.fullscreen.title": "Pantalla completa",
                "button.exit_room.title": "Salir de la sala",
                "button.exit_call.title": "Salir de la llamada",
                "button.logout.title": "Cerrar sesión",
                "button.modes.title": "Cambiar modo de visualización",

                // ========== Edit profile Texts ==========
                "edit.profile.title": "Editar Perfil",
                "edit.profile.name": "Editar perfil",
                "edit.profile.password": "Cambiar contraseña",
                "edit.profile.others": "Ajustes de usuario",
                "edit.profile.edit.name": "Nombre",
                "edit.profile.edit.surnames": "Apellidos",
                "edit.profile.settings.autoCall": "Respuesta automática",
                "edit.profile.settings.autoCallTimeout": "Tiempo de respuesta",
                "edit.profile.form.required": "El campo es obligatorio",
                "edit.profile.settings.autoCall.on": "Activado",
                "edit.profile.settings.autoCall.off": "Desactivado",
                "edit.profile.settings.autoCallTimeout.option": "{{ seconds }} segundos",
                "edit.password.required": "La nueva contraseña es obligatoria",
                "edit.password.requiredRepeat": "Es necesario repetir la nueva contraseña",
                "edit.profile.success": "Tus datos han sido actualizados correctamente",
                "edit.profile.app-info": "Información de la aplicación",
                "edit.profile.app-info.version": "Versión",
                'edit.profile.app-network': "Abrir aplicación de red",

                // ========== Form Texts ==========
                "form.update.title": "Actualizar",
                "form.update.name.title": "Nombre y apellidos actualizados",
                "form.update.name.success": "Tus datos han sido actualizados correctamente",
                "form.update.password.title": "Contraseña actualizada",
                "form.update.password.success": "Tu contraseña ha sido actualizada correctamente",
                "form.errors.passwords": "Las contraseñas no coinciden",
                "form.update.settings.title": "Ajustes de usuario actualizados",
                "form.update.settings.success": "Tus ajustes han sido actualizados correctamente",

                // ========== Download File Texts ==========
                "download.title": "Fichero compartido por {{ username }}",
                "download.message": "{{ username }} desea enviarle un fichero",
                "download.accept": "Descargar",
                "download.reject": "Cancelar",

                // ========== Share File Texts ==========
                "share-file.title": "Compartir fichero",
                "share-file.accept": "Compartir",
                "share-file.participants": "Seleccionar participantes",
                "share-file.file": "Seleccionar fichero",
                "share-file.filename": "Nombre del fichero",
                "share-file.file-selected": "Fichero seleccionado: {{ filename }}",
                "share-file.no-file": "Ningún fichero seleccionado",

                // ========== Dropdown Texts ==========
                "dropdown-menu.edit-profile": "Editar Perfil",
                "dropdown-menu.change-password": "Cambiar Contraseña",
                "dropdown-menu.user-settings": "Ajustes de Usuario",
                "dropdown-menu.logout": "Cerrar Sesión",

                // ========== Volume Titles ==========
                "volume.title": "Volumen",

                // ========== Modal Texts ========
                "modal.warning.title": "Advertencia",
                "modal.error.email.title": "Error al verificar correo",
                "modal.warning.email.title": "Advertencia",
                "modal.success.email.title": "Correo confirmado",
                "modal.logout.title": "Cerrar Sesión",
                "modal.logout.content": "¿Estás seguro que deseas cerrar sesión?",
                "modal.logout.confirm": "Cerrar Sesión",
                "modal.logout.cancel": "Cancelar",
                "modal.register.success.title": "Registro completado",
                "modal.register.success.description": "Registro completado correctamente. Por favor, compruebe su correo electrónico para verificar su cuenta",
                "modal.register.success.auto_confirm": "Registro completado correctamente. El equipo administrativo de Sippar se pondrá en contacto contigo cuando tengas acceso",
                "modal.forgot.success.title": "Restablecimiento de contraseña",
                "modal.forgot.success.description": "Si el correo electrónico proporcionado pertenece a una cuenta registrada, recibirá un correo con las instrucciones para restablecer su contraseña. Por favor, compruebe su bandeja de entrada y spam",
                "modal.restore.success.title": "Contraseña restablecida",
                "modal.restore.success.description": "La contraseña ha sido restablecida correctamente. Por favor, inicie sesión con su nueva contraseña",
                "modal.change-password.success.title": "Contraseña establecida",
                "modal.change-password.success.description": "La contraseña ha sido establecida correctamente. Por favor, inicie sesión con su nueva contraseña",
                "modal.warning.email.description_login": "Revisa tú bandeja de correo y espera a que el administrador apruebe tu cuenta. Recibirás un correo cuando tu cuenta sea aprobada",
                "modal.success.email.description_default": "Registro completado correctamente. El equipo administrativo de Sippar se pondrá en contacto contigo para activar tu cuenta",
                "button.modal.close": "Cerrar",
                "modal.inactivity.title": "Inactividad",
                "modal.inactivity.description": "Has estado inactivo por mucho tiempo. La sesión se cerrará automáticamente.",
                "modal.inactivity.button": "Cerrar sesión",

                // ========== Recording Texts ==========
                "recording.title": "Grabación en curso",

                // ========== Share File Errors ==========
                "share-file.errors.general": "Error al compartir el fichero",
                "share-file.errors.participants": "Debes seleccionar al menos un participante",
                "share-file.errors.file": "Debes seleccionar un fichero",
                "share-file.errors.filename": "Debes introducir un nombre para el fichero",
                "share-file.errors.file-size": "Tamaño de fichero no permitido. El tamaño máximo permitido es de 10MB",
                "share-file.errors.file-type": "Formato de fichero no permitido. Solo se permiten ficheros de tipo PDF, TXT, ODT, DOC, DOCX, ODS, XLS, XLSX, ODP, PPT, PPTX e imágenes",

                // =========== Joystick Texts ===========
                "joystick.zoom": "Zoom",
                "joystick.pan": "Horizontal",
                "joystick.tilt": "Vertical",

                // =========== Help Texts ===========
                "help.title": "Ayuda",
                "help.search": "Buscar...",
                "help.no-results": "No se encontraron resultados para '{{ searchTerm }}'",
                "hep.back": " Volver al índice de ayuda",

                // =========== Button Texts ===========
                "button.language.title": "Cambiar idioma",
                "button.reload.title": "Recargar aplicación",

                // =========== Updater Texts ===========
                "form.updater.progress": "Descargando actualización {{ version }}: {{ progress }}%",
                "form.updater.failed": "La actualización falló. Por favor, inténtalo más tarde.",
                "form.updater.checking": "Buscando actualizaciones...",
                "form.updater.up-to-date": "Tu aplicación está actualizada",
                "form.updater.not-available": "No hay actualizaciones disponibles en este momento",
                "form.updater.check.title": "Buscar actualizaciones",
                "form.updater.no-download-available": "No hay actualizaciones disponibles en este momento",
              }
        },

        en: {
          translation: {

             // ========== Login Texts ==========
             "login.title.start": "Sign in to ",
             "login.title.end": "with your email and password",
             "login.email": "Email",
             "login.password": "Password",
             "login.submit": "Sign In",
             "login.alternative": "or you can also create an account",
             "login.register": "Register",
             "login.forgot": "Forgot your password?",

             // ========== Forgot Texts ==========
             "forgot.title": "Recover Password",
             "forgot.email": "Email",
             "forgot.submit": "Send",
             "forgot.login": "Back to Sign In",

             // ========== Restore Texts ==========
             "restore.title": "Restore Password",
             "restore.password": "New password",
             "restore.repeatPassword": "Repeat Password",
             "restore.submit": "Restore Password",
             "restore.passwordsEmpty": "Passwords cannot be empty",
             "restore.passwordsDontMatch": "Passwords do not match",

             // ========== Change Password Texts ==========
             "change-password.title": "Set Password",
             "change-password.message": "Enter your access password",
             "change-password.password": "Password",
             "change-password.repeatPassword": "Repeat Password",
             "change-password.passwordsEmpty": "Passwords cannot be empty",
             "change-password.passwordsDontMatch": "Passwords do not match",
             "change-password.submit": "Set Password",

             // ========== Register Texts ==========
             "register.title.start": "Register in ",
             "register.title.end": "and access all services",
             "register.success": " Registration completed correctly. The administrative team of Sippar will contact you to activate your account",
             "register.success.alternative": "Once your account is activated you will be able to <br /> access all the services of <b>Sippar</b>",
             "register.login": "Sign In",
             "register.invitation": "Invitation code",
             "register.name": "Name",
             "register.surname": "Surname",
             "register.email": "Email",
             "register.submit": "Register",
             "register.password": "Password",
             "register.repeatPassword": "Repeat Password",
             "register.legal": "I accept",
             "register.conditions": "the conditions",
             "register.alternative": "or if you already have an account",
             "register.password.info":  "The password must contain:",
             "register.password.info.length": "At least 8 characters",
             "register.password.info.uppercase": "At least one uppercase letter",
             "register.password.info.lowercase": "At least one lowercase letter",
             "register.password.info.number": "At least one number",
             "register.password.info.special": "At least one special character",

             // ========== Schedule Texts ==========
             "schedule.title": "Teleconsultations",
             "schedule.add": "ADD",
             "schedule.jumpstep": "SKIP STEP",
             "schedule.future": "Upcoming",
             "schedule.past": "Past",
             "schedule.search": "Search...",
             "schedule.empty": "No results",
             "schedule.delete.title": "Delete teleconsultation",
             "schedule.delete.success": "The teleconsultation has been deleted",
             "schedule.delete.information": "Only the author of the appointment can delete it. Once the information is deleted it cannot be recovered, although ",
             "schedule.delete.information2": " has perpetual saving of information for data security",
             "schedule.delete.submit": "Delete teleconsultation",
             "schedule.insert.back": "Back",
             "schedule.insert.success": "The teleconsultation has been created correctly",
             "schedule.insert.subject": "Subject",
             "schedule.insert.start": "Start",
             "schedule.insert.end": "End",
             "schedule.insert.length": "Duration",
             "schedule.insert.info": "Fill in the following fields to create a teleconsultation:",
             "schedule.insert.info.subject": "The subject must be a minimum of 5 characters and a maximum of 500",
             "schedule.insert.info.date": "The start date and time must be after the current date and time",
             "schedule.insert.info.length": "The minimum duration is 10 minutes and the maximum is 3 hours",
             "schedule.insert.submit": "Create teleconsultation",
             "schedule.select.patient.title": "Select the Patient",
             "schedule.select.patient.search": "Search...",
             "schedule.select.patient.empty": "No results",
             "schedule.select.search": "Search...",
             "schedule.select.title": "Participants",
             "schedule.select.error": "An error occurred while loading the participants of the teleconsultation",
             "schedule.select.errorbtn": "Retry",
             "schedule.update.back": "Back",
             "schedule.update.participants": "Participants",
             "schedule.update.success": "Teleconsultation updated",
             "schedule.update.subject": "Subject",
             "schedule.update.start": "Start",
             "schedule.update.end": "End",
             "schedule.update.submit": "SAVE",

             // ========== Directory Texts ==========
             "directory.title": "Patients",
             "directory.add": "ADD",
             "directory.search": "Search...",
             "directory.empty": "No results",
             "directory.delete.title": "Delete Patient",
             "directory.delete.success": "Patient deleted",
             "directory.delete.information": "Only the author of the patient can delete the patient's file. Once the information is deleted it cannot be recovered, although <b>Sippar</b> has perpetual saving of information for data security",
             "directory.delete.submit": "Delete Patient",
             "directory.insert.title": "New Patient",
             "directory.insert.success": "The patient has been created correctly",
             "directory.insert.name": "Name",
             "directory.insert.surname": "Surname",
             "directory.insert.document": "Identification",
             "directory.insert.information": "Personal Details",
             "directory.insert.born": "Date of Birth",
             "directory.insert.occupation": "Occupation",
             "directory.insert.observations": "Observations",
             "directory.insert.history": "Medical History",
             "directory.insert.history.date": "Date",
             "directory.insert.history.type": "Type of Note",
             "directory.insert.history.type.1": "Telematic",
             "directory.insert.history.type.2": "Presencial",
             "directory.insert.history.type.3": "Review of History",
             "directory.insert.history.type.4": "Results Analysis",
             "directory.insert.history.delete": "Delete Entry",
             "directory.insert.history.add": "Add Entry",
             "directory.insert.address": "Address",
             "directory.insert.street": "Street",
             "directory.insert.city": "City",
             "directory.insert.zip": "Zip Code",
             "directory.insert.country": "Country",
             "directory.insert.contact": "Contact",
             "directory.insert.email": "Email",
             "directory.insert.phone": "Phone",
             "directory.insert.phisic": "Physiological Details",
             "directory.insert.genre": "Genre",
             "directory.insert.genre.1": "Man",
             "directory.insert.genre.2": "Woman",
             "directory.insert.genre.3": "Another",
             "directory.insert.height": "Height",
             "directory.insert.height.placeholder": "Measure in cm",
             "directory.insert.size": "Weight",
             "directory.insert.size.placeholder": "Measure in kg",
             "directory.insert.submit": "Create Patient",
             "directory.update.title": "Edit Patient",
             "directory.update.success": "Modified correctly",
             "directory.update.name": "Name",
             "directory.update.surname": "Surname",
             "directory.update.document": "Identification",
             "directory.update.information": "Personal Details",
             "directory.update.born": "Date of Birth",
             "directory.update.occupation": "Occupation",
             "directory.update.observations": "Observations",
             "directory.update.history": "Medical History",
             "directory.update.history.date": "Date",
             "directory.update.history.type": "Type of Note",
             "directory.update.history.type.1": "Telematic",
             "directory.update.history.type.2": "Presential",
             "directory.update.history.type.3": "Review of History",
             "directory.update.history.type.4": "Results Analysis",
             "directory.update.history.delete": "Delete Entry",
             "directory.update.history.add": "Add Entry",
             "directory.update.address": "Address",
             "directory.update.street": "Street",
             "directory.update.city": "City",
             "directory.update.zip": "Zip Code",
             "directory.update.country": "Country",
             "directory.update.contact": "Contact",
             "directory.update.email": "Email",
             "directory.update.phone": "Phone",
             "directory.update.phisic": "Physiological Details",
             "directory.update.genre": "Genre",
             "directory.update.genre.1": "Man",
             "directory.update.genre.2": "Woman",
             "directory.update.genre.3": "Another",
             "directory.update.height": "Height",
             "directory.update.height.placeholder": "Measure in cm",
             "directory.update.size": "Weight",
             "directory.update.size.placeholder": "Measure in kg",
             "directory.update.submit": "Edit Patient",

             // ========== Call Texts ==========
             "call.title": "Call",
             "call.message": "You have received a request to join a videoconference. Accept the call and access the broadcast",
             "call.accept": "Accept",
             "call.reject": "Reject",
             "call.rec.info": "The following call will be recorded",
             "call.auto": "The call will start automatically in",

             // ========== Controls Texts ==========
             "controls.speed": "Speed",
             "controls.gallery": "Gallery",
             "controls.share.stop": "Stop presenting",
             "controls.share.another": "Present something else",
             "controls.focus": "Focus",

             // ========== Creator Texts ==========
             "creator.title": "Start teleconsultation",
             "creator.subtitle": "Create a teleconsultation and invite the rest of the participants. You can participate with multiple connected devices",
             "creator.start": "Call",
             "creator.video": "Local exploration",
             "creator.users.start": "There are",
             "creator.users.middle": "available",
             "creator.users.end": "users",
             "creator.micro.selector": "Microphone selection",
             "creator.micro.no_devices": "Voice device not found",
             "creator.micro.placeholder": "Select a channel",
             "creator.headphone.selector": "Headphone selection",
             "creator.headphone.no_devices": "Audio device not found",
             "creator.headphone.placeholder": "Select a channel",
             "creator.video.selector": "Camera selection",
             "creator.video.no_devices": "Video device not found",
             "creator.video.placeholder": "Select a channel",
             "creator.background.selector": "Background selection",
             "creator.update": "UPDATE",

             // ========== Device Texts ==========
             "info.device.not.available": "The default audio/video device could not be obtained, please check that the device is connected and not being used by another application",
             "warn.device.audio.not.valid": "The selected '{{ label }}' audio device cannot be used as an input audio device. Please select another device",
             "warn.device.video.not.valid": "The selected '{{ label }}' video device cannot be used as an input video device. Please select another device",

             // ========== Media Texts ==========
             "media.selector.fail": "The {{appName}} application could not be located, please make sure the application is open and try again",
             "media.selector.wait": "Waiting for the {{appName}} application...",
             "media.selector.success": "Sharing {{appName}}",
             "media.selector.disconnecting": "Disconnecting from {{appName}}...",
             "media.selector.disconnected": "{{ appName }} application disconnected",
             "media.selector.open-app": "Open application",
              "media.selector.close-app": "Close application",

             // ========== Error Texts ==========
             "error.title": "No connection",
             "error.description": "Could not connect to the server. Please check your internet connection.",
             "error.button": "Retry",

             // ========== Force Texts ==========
             "force.title": "Do you want to log out?",
             "force.description": "You cannot log in on two devices at the same time. If you want to log in on this device, you must log out on the other device",
             "force.yes": " Use this device",
             "force.no": "Log out",

             // ========== Participant Texts ==========
             "participants.title": "Participants",
             "participants.actives": "Actives",
             "participants.availables": "Availables",
             "participants.empty": "No participants",
             "participants.allUsers": "All users",
             "participants.inCall": "In the call",
             "participants.noClient": "No client",
             "participants.search": "Search user...",
             "participants.noUsers": "No users available with the entered name",

             // ========== Chat Texts ==========
             "dish.chat.form.placeholder": "Write a message to everyone...",

             // ========== Selector share Texts ==========
             "selector.empty": "",
             "selector.share.title": "Share",
             "selector.share.subtitle": "Screen",

             // ========== Settings Texts ==========
             "settings.stream-info.title": "Stream Information",
             "settings.status": "Stream Status",
             "settings.audio.placeholder": "Change audio channel",
             "settings.video.placeholder": "Change video channel",
             "settings.background.placeholder": "Change video background",
             "settings.background.delete": "Remove background",
             "settings.title": "Settings",
             "settings.video": "Video Status",
             "settings.rate": "Frame rate",
             "settings.aspect": "Aspect ratio",
             "settings.size": "Size",
             "settings.audio": "Audio Status",
             "settings.muted": "Volume",
             "settings.noiseSuppression": "Noise suppression",
             "settings.sampleRate": "Sample rate",
             "settings.sampleSize": "Sample size",
             "settings.echoCancellation": "Echo cancellation",
             "settings.latency": "Latency",
             "settings.autoGainControl": "Automatic gain control",
             "settings.stream": "Stream Status",
             "settings.enabled": "Stream Status",
             "settings.readyState": "Connection status",
             "settings.kind": "Stream Type",
             "settings.name": "Name",
             "settings.audio.selector": "You can select the audio output channel of your device, so you can listen to the call on the device you want",
             "settings.audioInput.selector": "You can select the audio input channel of your device, so you can share the microphone you want",
             "settings.video.selector": "You can select the video input channel of your device, so you can share the camera you want",
             "settings.background.selector": "You can select the background you want to share in the call, so you can customize your environment",

             // ========== Button Texts ==========
             "button.video.screenshot.title": "Take a screenshot",
             "button.video.toggle.title": "Enable/Disable video",
             "button.video.audio.toggle.title": "Enable/Disable audio",
             "button.video.ptz.control.title": "Show/Hide PTZ controls",
             "button.video.ptz.flip.title": "Flip PTZ camera",
             "button.video.status.title": "Status",
             "button.video.settings.title": "Settings",
             "button.calendar.title": "Schedule a teleconsultation in the calendar",
             "button.notes.title": "Manage patients",
             "button.info.title": "Connection information",
             "button.share.title": "Share medical equipment and applications",
             "button.directShare.title": "Share screen",
             "button.participants.title": "Participants list",
             "button.chat.title": "Chat",
             "button.edit.title": "Edit profile",
             "button.fullscreen.title": "Full screen",
             "button.exit_room.title": "Exit room",
             "button.exit_call.title": "Exit call",
             "button.logout.title": "Log out",
             "button.modes.title": "Change display mode",

             // ========== Edit profile Texts ==========
             "edit.profile.title": "Edit Profile",
             "edit.profile.name": "Edit profile",
             "edit.profile.password": "Change password",
             "edit.profile.others": "User settings",
             "edit.profile.edit.name": "Name",
             "edit.profile.edit.surnames": "Surnames",
             "edit.profile.settings.autoCall": "Automatic response",
             "edit.profile.settings.autoCallTimeout": "Response time",
             "edit.profile.form.required": "The field is required",
             "edit.profile.settings.autoCall.on": "Enabled",
             "edit.profile.settings.autoCall.off": "Disabled",
             "edit.profile.settings.autoCallTimeout.option": "{{ seconds }} seconds",
             "edit.password.required": "The new password is required",
             "edit.password.requiredRepeat": "It is necessary to repeat the new password",
             "edit.profile.success": "Your data has been updated correctly",
             'edit.profile.app-info': "Application information",
             'edit.profile.app-info.version': "Version",
             'edit.profile.app-network': "Open network application",

             // ========== Form Texts ==========
             "form.update.title": "Update",
             "form.update.name.title": "Name and surnames updated",
             "form.update.name.success": "Your data has been updated correctly",
             "form.update.password.title": "Password updated",
             "form.update.password.success": "Your password has been updated correctly",
             "form.errors.passwords": "Passwords do not match",
             "form.update.settings.title": "User settings updated",
             "form.update.settings.success": "Your settings have been updated correctly",

             // ========== Download File Texts ==========
             "download.title": "File shared by {{ username }}",
             "download.message": "{{ username }} wants to send you a file",
             "download.accept": "Download",
             "download.reject": "Cancel",

             // ========== Share File Texts ==========
             "share-file.title": "Share file",
             "share-file.accept": "Share",
             "share-file.participants": "Select participants",
             "share-file.file": "Select file",
             "share-file.filename": "File name",
             "share-file.file-selected": "File selected: {{ filename }}",
             "share-file.no-file": "No file selected",

             // ========== Dropdown Texts ==========
             "dropdown-menu.edit-profile": "Edit Profile",
             "dropdown-menu.change-password": "Change Password",
             "dropdown-menu.user-settings": "User Settings",
             "dropdown-menu.logout": "Log out",

             // ========== Volume Titles ==========
             "volume.title": "Volume",

             // ========== Modal Texts ========
             "modal.warning.title": "Warning",
             "modal.error.email.title": "Error verifying email",
             "modal.warning.email.title": "Warning",
             "modal.success.email.title": "Email confirmed",
             "modal.logout.title": "Log out",
             "modal.logout.content": "Are you sure you want to log out?",
             "modal.logout.confirm": "Log out",
             "modal.logout.cancel": "Cancel",
             "modal.register.success.title": "Registration completed",
             "modal.register.success.description": "Registration completed correctly. Please check your email to verify your account",
             "modal.register.success.auto_confirm": "Registration completed correctly. The administrative team of Sippar will contact you when you have access",
             "modal.forgot.success.title": "Password reset",
             "modal.forgot.success.description": "If the email provided belongs to a registered account, you will receive an email with instructions to reset your password. Please check your inbox and spam",
             "modal.restore.success.title": "Password reset",
             "modal.restore.success.description": "The password has been reset correctly. Please log in with your new password",
             "modal.change-password.success.title": "Password set",
             "modal.change-password.success.description": "The password has been set correctly. Please log in with your new password",
             "modal.warning.email.description_login": "Check your email and wait for the administrator to approve your account. You will receive an email when your account is approved",
             "modal.success.email.description_default": "Registration completed correctly. The administrative team of Sippar will contact you to activate your account",
             "button.modal.close": "Close",
             "modal.inactivity.title": "Inactivity",
             "modal.inactivity.description": "You have been inactive for too long. The session will close automatically.",
             "modal.inactivity.button": "Log out",

             // ========== Recording Texts ==========
             "recording.title": "Recording in progress",

             // ========== Share File Errors ==========
             "share-file.errors.general": "Error sharing the file",
             "share-file.errors.participants": "You must select at least one participant",
             "share-file.errors.file": "You must select a file",
             "share-file.errors.filename": "You must enter a name for the file",
             "share-file.errors.file-size": "File size not allowed. The maximum allowed size is 10MB",
             "share-file.errors.file-type": "File format not allowed. Only PDF, TXT, ODT, DOC, DOCX, ODS, XLS, XLSX, ODP, PPT, PPTX and image files are allowed",

             // =========== Joystick Texts ===========
             "joystick.zoom": "Zoom",
             "joystick.pan": "Horizontal",
             "joystick.tilt": "Vertical",

             // ========== Help Texts ==========
             "help.title": "Help",
             "help.search": "Search...",
             "help.no-results": "No results found for the search: '{{searchTerm}}'",
             "help.back": " Back to help index",

              // =========== Button Texts ===========
              "button.language.title": "Change language",
              "button.reload.title": "Reload application",

                // ========== Updater Texts ==========
              "form.updater.progress": "Downloading update {{ version }}: {{ progress }}%",
              "form.updater.failed": "Update failed. Please try again later.",
              "form.updater.checking": "Checking for updates...",
              "form.updater.up-to-date": "Your application is up to date",
              "form.updater.not-available": "No updates available at this time",
              "form.updater.check.title": "Check for updates",
              "form.updater.no-download-available": "No download available",
          },
        },
        fr: {
          translation: {
            // ========== Login Texts ==========
            "login.title.start": "Connectez-vous à ",
            "login.title.end": "avec votre email et mot de passe",
            "login.email": "Email",
            "login.password": "Mot de passe",
            "login.submit": "Se connecter",
            "login.alternative": "ou vous pouvez également créer un compte",
            "login.register": "S'inscrire",
            "login.forgot": "Vous avez oublié votre mot de passe?",

            // ========== Forgot Texts ==========
            "forgot.title": "Récupérer le mot de passe",
            "forgot.email": "Email",
            "forgot.submit": "Envoyer",
            "forgot.login": "Retour à la connexion",

            // ========== Restore Texts ==========
            "restore.title": "Réinitialiser le mot de passe",
            "restore.password": "Nouveau mot de passe",
            "restore.repeatPassword": "Répéter le mot de passe",
            "restore.submit": "Réinitialiser le mot de passe",
            "restore.passwordsEmpty": "Les mots de passe ne peuvent pas être vides",
            "restore.passwordsDontMatch": "Les mots de passe ne correspondent pas",

            // ========== Change Password Texts ==========
            "change-password.title": "Définir le mot de passe",
            "change-password.message": "Entrez votre mot de passe d'accès",
            "change-password.password": "Mot de passe",
            "change-password.repeatPassword": "Répéter le mot de passe",
            "change-password.passwordsEmpty": "Les mots de passe ne peuvent pas être vides",
            "change-password.passwordsDontMatch": "Les mots de passe ne correspondent pas",
            "change-password.submit": "Définir le mot de passe",

            // ========== Register Texts ==========
            "register.title.start": "Inscrivez-vous à ",
            "register.title.end": "et accédez à tous les services",
            "register.success": " Inscription complétée avec succès. L'équipe administrative de Sippar vous contactera pour activer votre compte",
            "register.success.alternative": "Une fois votre compte activé, vous pourrez accéder à tous les services de Sippar",
            "register.login": "Se connecter",
            "register.invitation": "Code d'invitation",
            "register.name": "Nom",
            "register.surname": "Prénom",
            "register.email": "Email",
            "register.submit": "S'inscrire",
            "register.password": "Mot de passe",
            "register.repeatPassword": "Répéter le mot de passe",
            "register.legal": "J'accepte",
            "register.conditions": "les conditions",
            "register.alternative": "ou si vous avez déjà un compte",
            "register.password.info": "Le mot de passe doit contenir:",
            "register.password.info.length": "Au moins 8 caractères",
            "register.password.info.uppercase": "Au moins une lettre majuscule",
            "register.password.info.lowercase": "Au moins une lettre minuscule",
            "register.password.info.number": "Au moins un chiffre",
            "register.password.info.special": "Au moins un caractère spécial",

            // ========== Schedule Texts ==========
            "schedule.title": "Téléconsultations",
            "schedule.add": "AJOUTER",
            "schedule.jumpstep": "SAUTER ÉTAPE",
            "schedule.future": "À venir",
            "schedule.past": "Passées",
            "schedule.search": "Rechercher...",
            "schedule.empty": "Aucun résultat",
            "schedule.delete.title": "Supprimer la téléconsultation",
            "schedule.delete.success": "La téléconsultation a été supprimée",
            "schedule.delete.information": "Seul l'auteur du rendez-vous peut le supprimer. Une fois supprimée, l'information ne pourra pas être récupérée, bien que ",
            "schedule.delete.information2": " dispose d'un enregistrement perpétuel des informations pour la sécurité des données",
            "schedule.delete.submit": "Supprimer la téléconsultation",
            "schedule.insert.back": "Retour",
            "schedule.insert.success": "La téléconsultation a été créée avec succès",
            "schedule.insert.subject": "Sujet",
            "schedule.insert.start": "Début",
            "schedule.insert.end": "Fin",
            "schedule.insert.length": "Durée",
            "schedule.insert.info": "Remplissez les champs suivants pour créer une téléconsultation:",
            "schedule.insert.info.subject": "Le sujet doit comporter un minimum de 5 caractères et un maximum de 500",
            "schedule.insert.info.date": "La date et l'heure de début doivent être postérieures à la date et l'heure actuelles",
            "schedule.insert.info.length": "La durée minimale est de 10 minutes et la maximale de 3 heures",
            "schedule.insert.submit": "Créer une téléconsultation",
            "schedule.select.patient.title": "Sélectionner le patient",
            "schedule.select.patient.search": "Rechercher...",
            "schedule.select.patient.empty": "Aucun résultat",
            "schedule.select.search": "Rechercher...",
            "schedule.select.title": "Participants",
            "schedule.select.error": "Une erreur s'est produite lors du chargement des participants à la téléconsultation",
            "schedule.select.errorbtn": "Réessayer",
            "schedule.update.back": "Retour",
            "schedule.update.participants": "Participants",
            "schedule.update.success": "Téléconsultation mise à jour",
            "schedule.update.subject": "Sujet",
            "schedule.update.start": "Début",
            "schedule.update.end": "Fin",
            "schedule.update.submit": "ENREGISTRER",

            // ========== Directory Texts ==========
            "directory.title": "Patients",
            "directory.add": "AJOUTER",
            "directory.search": "Rechercher...",
            "directory.empty": "Aucun résultat",
            "directory.delete.title": "Supprimer le patient",
            "directory.delete.success": "Patient supprimé",
            "directory.delete.information": "Seul l'auteur du patient peut supprimer le dossier du patient. Une fois supprimée, l'information ne pourra pas être récupérée, bien que Sippar dispose d'un enregistrement perpétuel des informations pour la sécurité des données",
            "directory.delete.submit": "Supprimer le patient",
            "directory.insert.title": "Nouveau patient",
            "directory.insert.success": "Le patient a été créé avec succès",
            "directory.insert.name": "Nom",
            "directory.insert.surname": "Prénom",
            "directory.insert.document": "Identification",
            "directory.insert.information": "Détails personnels",
            "directory.insert.born": "Date de naissance",
            "directory.insert.occupation": "Occupation",
            "directory.insert.observations": "Observations",
            "directory.insert.history": "Historique médical",
            "directory.insert.history.date": "Date",
            "directory.insert.history.type": "Type de note",
            "directory.insert.history.type.1": "Téléconsultation",
            "directory.insert.history.type.2": "Présentiel",
            "directory.insert.history.type.3": "Révision de l'historique",
            "directory.insert.history.type.4": "Analyse des résultats",
            "directory.insert.history.delete": "Supprimer l'entrée",
            "directory.insert.history.add": "Ajouter une entrée",
            "directory.insert.address": "Adresse",
            "directory.insert.street": "Rue",
            "directory.insert.city": "Ville",
            "directory.insert.zip": "Code postal",
            "directory.insert.country": "Pays",
            "directory.insert.contact": "Contact",
            "directory.insert.email": "Email",
            "directory.insert.phone": "Téléphone",
            "directory.insert.phisic": "Détails physiologiques",
            "directory.insert.genre": "Genre",
            "directory.insert.genre.1": "Homme",
            "directory.insert.genre.2": "Femme",
            "directory.insert.genre.3": "Autre",
            "directory.insert.height": "Taille",
            "directory.insert.height.placeholder": "Mesure en cm",
            "directory.insert.size": "Poids",
            "directory.insert.size.placeholder": "Mesure en kg",
            "directory.insert.submit": "Créer un patient",
            "directory.update.title": "Modifier le patient",
            "directory.update.success": "Modifié avec succès",
            "directory.update.name": "Nom",
            "directory.update.surname": "Prénom",
            "directory.update.document": "Identification",
            "directory.update.information": "Détails personnels",
            "directory.update.born": "Date de naissance",
            "directory.update.occupation": "Occupation",
            "directory.update.observations": "Observations",
            "directory.update.history": "Historique médical",
            "directory.update.history.date": "Date",
            "directory.update.history.type": "Type de note",
            "directory.update.history.type.1": "Téléconsultation",
            "directory.update.history.type.2": "Présentiel",
            "directory.update.history.type.3": "Révision de l'historique",
            "directory.update.history.type.4": "Analyse des résultats",
            "directory.update.history.delete": "Supprimer l'entrée",
            "directory.update.history.add": "Ajouter une entrée",
            "directory.update.address": "Adresse",
            "directory.update.street": "Rue",
            "directory.update.city": "Ville",
            "directory.update.zip": "Code postal",
            "directory.update.country": "Pays",
            "directory.update.contact": "Contact",
            "directory.update.email": "Email",
            "directory.update.phone": "Téléphone",
            "directory.update.phisic": "Détails physiologiques",
            "directory.update.genre": "Genre",
            "directory.update.genre.1": "Homme",
            "directory.update.genre.2": "Femme",
            "directory.update.genre.3": "Autre",
            "directory.update.height": "Taille",
            "directory.update.height.placeholder": "Mesure en cm",
            "directory.update.size": "Poids",
            "directory.update.size.placeholder": "Mesure en kg",
            "directory.update.submit": "Modifier le patient",

            // ========== Call Texts ==========
            "call.title": "Appel",
            "call.message": "Vous avez reçu une demande pour rejoindre une vidéoconférence. Acceptez l'appel et accédez à la diffusion",
            "call.accept": "Accepter",
            "call.reject": "Rejeter",
            "call.rec.info": "Le prochain appel sera enregistré",
            "call.auto": "L'appel commencera automatiquement dans",

            // ========== Controls Texts ==========
            "controls.speed": "Vitesse",
            "controls.gallery": "Galerie",
            "controls.share.stop": "Arrêter de présenter",
            "controls.share.another": "Présenter autre chose",
            "controls.focus": "Focus",

            // ========== Creator Texts ==========
            "creator.title": "Démarrer une téléconsultation",
            "creator.subtitle": "Créez une téléconsultation et invitez les autres participants. Vous pourrez participer avec plusieurs appareils connectés",
            "creator.start": "Appeler",
            "creator.video": "Exploration locale",
            "creator.users.start": "Il y a",
            "creator.users.middle": "utilisateurs",
            "creator.users.end": "disponibles",
            "creator.micro.selector": "Sélection de microphone",
            "creator.micro.no_devices": "Aucun dispositif vocal trouvé",
            "creator.micro.placeholder": "Sélectionnez un canal",
            "creator.headphone.selector": "Sélection d'écouteurs",
            "creator.headphone.no_devices": "Aucun dispositif audio trouvé",
            "creator.headphone.placeholder": "Sélectionnez un canal",
            "creator.video.selector": "Sélection de caméra",
            "creator.video.no_devices": "Aucun dispositif vidéo trouvé",
            "creator.video.placeholder": "Sélectionnez un canal",
            "creator.background.selector": "Sélection de fond",
            "creator.update": "METTRE À JOUR",

            // ========== Device Texts ==========
            "info.device.not.available": "Impossible d'obtenir le dispositif audio/vidéo par défaut, veuillez vérifier que le dispositif est connecté et n'est pas utilisé par une autre application",
            "warn.device.audio.not.valid": "Le dispositif '{{ label }}' audio sélectionné ne peut pas être utilisé comme dispositif d'entrée audio. Veuillez sélectionner un autre dispositif",
            "warn.device.video.not.valid": "Le dispositif '{{ label }}' vidéo sélectionné ne peut pas être utilisé comme dispositif d'entrée vidéo. Veuillez sélectionner un autre dispositif",

            // ========== Media Texts ==========
            "media.selector.fail": "Impossible de localiser l'application {{appName}}, veuillez vous assurer que l'application est ouverte et réessayer",
            "media.selector.wait": "En attente de l'application {{appName}}...",
            "media.selector.success": "Partage de {{appName}}",
            "media.selector.disconnecting": "Déconnexion de {{appName}}...",
            "media.selector.disconnected": "Application {{ appName }} déconnectée",
            "media.selector.open-app": "Ouvrir l'application",
            "media.selector.close-app": "Fermer l'application",

            // ========== Error Texts ==========
            "error.title": "Hors ligne",
            "error.description": "Impossible de se connecter au serveur. Veuillez vérifier votre connexion internet.",
            "error.button": "Réessayer",

            // ========== Force Texts ==========
            "force.title": "Voulez-vous vous déconnecter?",
            "force.description": "Il est impossible de se connecter sur deux appareils en même temps. Si vous souhaitez vous connecter sur cet appareil, vous devez vous déconnecter de l'autre appareil",
            "force.yes": " Utiliser cet appareil",
            "force.no": "Se déconnecter",

            // ========== Participant Texts ==========
            "participants.title": "Participants",
            "participants.actives": "Actifs",
            "participants.availables": "Disponibles",
            "participants.empty": "Aucun participant",
            "participants.allUsers": "Tous les utilisateurs",
            "participants.inCall": "En appel",
            "participants.noClient": "Sans client",
            "participants.search": "Rechercher un utilisateur...",
            "participants.noUsers": "Aucun utilisateur disponible avec le nom saisi",

            // ========== Chat Texts ==========
            "dish.chat.form.placeholder": "Écrire un message à tous...",

            // ========== Selector share Texts ==========
            "selector.empty": "",
            "selector.share.title": "Partager",
            "selector.share.subtitle": "Écran",

            // ========== Settings Texts ==========
            "settings.stream-info.title": "Informations sur le flux",
            "settings.status": "État du flux",
            "settings.audio.placeholder": "Changer de canal audio",
            "settings.video.placeholder": "Changer de canal vidéo",
            "settings.background.placeholder": "Changer de fond vidéo",
            "settings.background.delete": "Supprimer le fond",
            "settings.title": "Paramètres",
            "settings.video": "État de la vidéo",
            "settings.rate": "Fréquence d'images",
            "settings.aspect": "Ratio d'aspect",
            "settings.size": "Taille",
            "settings.audio": "État de l'audio",
            "settings.muted": "Volume",
            "settings.noiseSuppression": "Suppression du bruit",
            "settings.sampleRate": "Fréquence d'échantillonnage",
            "settings.sampleSize": "Taille de l'échantillon",
            "settings.echoCancellation": "Annulation de l'écho",
            "settings.latency": "Latence",
            "settings.autoGainControl": "Contrôle automatique du gain",
            "settings.stream": "État du flux",
            "settings.enabled": "État du flux",
            "settings.readyState": "État de la connexion",
            "settings.kind": "Type de flux",
            "settings.name": "Nom",
            "settings.audio.selector": "Vous pouvez sélectionner le canal de sortie audio de votre appareil, afin que vous puissiez écouter l'appel sur l'appareil de votre choix",
            "settings.audioInput.selector": "Vous pouvez sélectionner le canal d'entrée audio de votre appareil, afin que vous puissiez partager le microphone de votre choix",
            "settings.video.selector": "Vous pouvez sélectionner le canal d'entrée vidéo de votre appareil, afin que vous puissiez partager la caméra de votre choix",
            "settings.background.selector": "Vous pouvez sélectionner le fond que vous souhaitez partager lors de l'appel, afin que vous puissiez personnaliser votre environnement",

            // ========== Button Texts ==========
            "button.video.screenshot.title": "Prendre une capture",
            "button.video.toggle.title": "Activer/Désactiver la vidéo",
            "button.video.audio.toggle.title": "Activer/Désactiver l'audio",
            "button.video.ptz.control.title": "Afficher/Masquer les contrôles PTZ",
            "button.video.ptz.flip.title": "Faire pivoter la caméra PTZ",
            "button.video.status.title": "Paramètres",
            "button.video.settings.title": "Paramètres",
            "button.calendar.title": "Programmer une téléconsultation dans le calendrier",
            "button.notes.title": "Gérer les patients",
            "button.info.title": "Informations de connexion",
            "button.share.title": "Partager des équipements médicaux et des applications",
            "button.directShare.title": "Partager l'écran",
            "button.participants.title": "Liste des participants",
            "button.chat.title": "Chat",
            "button.edit.title": "Modifier le profil",
            "button.fullscreen.title": "Plein écran",
            "button.exit_room.title": "Quitter la salle",
            "button.exit_call.title": "Quitter l'appel",
            "button.logout.title": "Se déconnecter",
            "button.modes.title": "Changer de mode d'affichage",

            // ========== Edit profile Texts ==========
            "edit.profile.title": "Modifier le profil",
            "edit.profile.name": "Modifier le profil",
            "edit.profile.password": "Changer le mot de passe",
            "edit.profile.others": "Paramètres utilisateur",
            "edit.profile.edit.name": "Nom",
            "edit.profile.edit.surnames": "Prénoms",
            "edit.profile.settings.autoCall": "Réponse automatique",
            "edit.profile.settings.autoCallTimeout": "Délai de réponse",
            "edit.profile.form.required": "Le champ est obligatoire",
            "edit.profile.settings.autoCall.on": "Activé",
            "edit.profile.settings.autoCall.off": "Désactivé",
            "edit.profile.settings.autoCallTimeout.option": "{{ seconds }} secondes",
            "edit.password.required": "Le nouveau mot de passe est obligatoire",
            "edit.password.requiredRepeat": "Il est nécessaire de répéter le nouveau mot de passe",
            "edit.profile.success": "Vos données ont été mises à jour avec succès",
            'edit.profile.app-info': "Informations sur l'application",
            'edit.profile.app-info.version': "Version",
            'edit.profile.app-network': "Ouvrir l'application réseau",

            // ========== Form Texts ==========
            "form.update.title": "Mettre à jour",
            "form.update.name.title": "Nom et prénoms mis à jour",
            "form.update.name.success": "Vos données ont été mises à jour avec succès",
            "form.update.password.title": "Mot de passe mis à jour",
            "form.update.password.success": "Votre mot de passe a été mis à jour avec succès",
            "form.errors.passwords": "Les mots de passe ne correspondent pas",
            "form.update.settings.title": "Paramètres utilisateur mis à jour",
            "form.update.settings.success": "Vos paramètres ont été mis à jour avec succès",

            // ========== Download File Texts ==========
            "download.title": "Fichier partagé par {{ username }}",
            "download.message": "{{ username }} souhaite vous envoyer un fichier",
            "download.accept": "Télécharger",
            "download.reject": "Annuler",

            // ========== Share File Texts ==========
            "share-file.title": "Partager un fichier",
            "share-file.accept": "Partager",
            "share-file.participants": "Sélectionner les participants",
            "share-file.file": "Sélectionner un fichier",
            "share-file.filename": "Nom du fichier",
            "share-file.file-selected": "Fichier sélection",
            "share-file.no-file": "Aucun fichier sélectionné",

            // ========== Dropdown Texts ==========
            "dropdown-menu.edit-profile": "Modifier le profil",
            "dropdown-menu.change-password": "Changer le mot de passe",
            "dropdown-menu.user-settings": "Paramètres utilisateur",
            "dropdown-menu.logout": "Se déconnecter",

            // ========== Volume Titles ==========
            "volume.title": "Volume",

            // ========== Modal Texts ========
            "modal.warning.title": "Avertissement",
            "modal.error.email.title": "Erreur de vérification de l'email",
            "modal.warning.email.title": "Avertissement",
            "modal.success.email.title": "Email confirmé",
            "modal.logout.title": "Se déconnecter",
            "modal.logout.content": "Êtes-vous sûr de vouloir vous déconnecter?",
            "modal.logout.confirm": "Se déconnecter",
            "modal.logout.cancel": "Annuler",
            "modal.register.success.title": "Inscription complétée",
            "modal.register.success.description": "Inscription complétée avec succès. Veuillez vérifier votre email pour confirmer votre compte",
            "modal.register.success.auto_confirm": "Inscription complétée avec succès. L'équipe administrative de Sippar vous contactera lorsque vous aurez accès",
            "modal.forgot.success.title": "Réinitialisation du mot de passe",
            "modal.forgot.success.description": "Si l'email fourni appartient à un compte enregistré, vous recevrez un email avec les instructions pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception et vos spams",
            "modal.restore.success.title": "Mot de passe réinitialisé",
            "modal.restore.success.description": "Le mot de passe a été réinitialisé avec succès. Veuillez vous connecter avec votre nouveau mot de passe",
            "modal.change-password.success.title": "Mot de passe défini",
            "modal.change-password.success.description": "Le mot de passe a été défini avec succès. Veuillez vous connecter avec votre nouveau mot de passe",
            "modal.warning.email.description_login": "Vérifiez votre boîte de réception et attendez que l'administrateur approuve votre compte. Vous recevrez un email lorsque votre compte sera approuvé",
            "modal.success.email.description_default": "Inscription complétée avec succès. L'équipe administrative de Sippar vous contactera pour activer votre compte",
            "button.modal.close": "Fermer",
            "modal.inactivity.title": "Inactivité",
            "modal.inactivity.description": "Vous avez été inactif trop longtemps. La session se fermera automatiquement.",
            "modal.inactivity.button": "Fermer la session",

            // ========== Recording Texts ==========
            "recording.title": "Enregistrement en cours",

            // ========== Share File Errors ==========
            "share-file.errors.general": "Erreur lors du partage du fichier",
            "share-file.errors.participants": "Vous devez sélectionner au moins un participant",
            "share-file.errors.file": "Vous devez sélectionner un fichier",
            "share-file.errors.filename": "Vous devez entrer un nom pour le fichier",
            "share-file.errors.file-size": "Taille de fichier non autorisée. La taille maximale autorisée est de 10 Mo",
            "share-file.errors.file-type": "Format de fichier non autorisé. Seuls les fichiers de type PDF, TXT, ODT, DOC, DOCX, ODS, XLS, XLSX, ODP, PPT, PPTX et les images sont autorisés",

            // =========== Joystick Texts ===========
            "joystick.zoom": "Zoom",
            "joystick.pan": "Horizontal",
            "joystick.tilt": "Vertical",

            // ========== Help Texts ==========
            "help.title": "Aide",
            "help.search": "Rechercher...",
            "help.no-results": "Aucun résultat trouvé pour la recherche: '{{searchTerm}}'",
            "help.back": " Retour à l'index de l'aide",

            // =========== Button Texts ===========
            "button.language.title": "Changer de langue",
            "button.reload.title": "Recharger l'application",

            // ========== Updater Texts ==========
            "form.updater.progress": "Téléchargement de la mise à jour {{ version }}: {{ progress }}%",
            "form.updater.failed": "La mise à jour a échoué. Veuillez réessayer plus tard.",
            "form.updater.checking": "Recherche de mises à jour...",
            "form.updater.up-to-date": "Votre application est à jour",
            "form.updater.not-available": "Aucune mise à jour disponible pour le moment",
            "form.updater.check.title": "Rechercher des mises à jour",
            "form.updater.no-download-available": "Aucune mise à jour disponible pour le moment",
          }
        }
    },
    lng: localStorage.getItem('language') ?? navigator.language.split("-")[0],
    fallbackLng: "es",
    interpolation: {
        escapeValue: false
    },
}


export const errorTranslations: {
    [key: string]: {
        [key: string]: string
    }
} = {
    "es": {
        "must be a string": "debe ser un texto",
        "must be shorter than or equal to": "debe ser menor o igual a",
        "must be longer than or equal to": "debe ser mayor o igual a",
        "must be longer than": "debe ser mayor a",
        "must be shorter than": "debe ser menor a",
        "must be a number conforming to the specified constraints": "debe ser un número",
        "must be a boolean value": "debe ser un valor booleano",
        "must be a valid email address": "debe ser un correo electrónico válido",
        "must be a valid date string": "debe ser una fecha válida",
        "must be a valid date": "debe ser una fecha válida",
        "must be a valid UUID": "debe ser un UUID válido",
        "must be a valid enum value": "debe ser un valor válido",
        "should not be empty": "no debe estar vacío",
        "must be an URL address": "debe ser una URL válida",
        "must not be greater than": "debe ser menor o igual a",
        "must not be less tha": "debe ser mayor o igual a",
        "must be a UUID": "debe ser identificación válido",
        "must be an email": "debe ser un correo electrónico válido",
        "must contain only letters (a-zA-Z)": "debe contener solo letras (a-zA-Z)",
        "must contain only letters and numbers": "debe contener solo letras y números",
        "must be a valid phone number": "debe ser un número de teléfono válido",
        "characters": "caracteres",
        "The group does not exist": "El grupo no existe",
        "You must be an administrator to delete a group": "Debes ser administrador para eliminar un grupo",
        "You must be an administrator to view groups": "Debes ser administrador para ver los grupos",
        "You must be an administrator to update a group": "Debes ser administrador para actualizar un grupo",
        "A group with this name already exists": "Ya existe un grupo con este nombre",
        "There is already a group with this invitation": "Ya existe un grupo con esta invitación",
        "You must be an administrator to create a group": "Debes ser administrador para crear un grupo",
        "Appointment not found": "Cita no encontrada",
        "Surely the user is already invited": "Seguramente el usuario ya está invitado",
        "You have another appointment at this time": "Tienes otra cita a esta hora",
        "To delete a citation you must be the author of it": "Para eliminar una cita debes ser el autor de la misma",
        "You cannot delete a patient that does not exist": "No puedes eliminar un paciente que no existe",
        "You cannot create a patient with the same ID": "No puedes crear un paciente con el mismo ID",
        "The password is not correct": "La contraseña no es correcta",
        "Surely the group already has the permission": "Seguramente el grupo ya tiene el permiso",
        "This group has reached the subscription limit": "Este grupo ha alcanzado el límite de suscripción",
        "Wrong invitation": "Invitación incorrecta",
    }
}

export function TranslateError(error: string) {

    // get window language:
    const language = navigator.language.split("-")[0];

    const errors = language === 'es' && errorTranslations[language];
    if (errors) {

        Object.keys(errors).forEach((key) => {
            error = error.replace(key, errors[key])
        })

        return error;
    }
    else return error;
}

/* eslint-disable max-lines */
// Modules
import { io, Socket } from "socket.io-client";

// Types
import { AvailableType, CallType, DishLogType, } from './../dish.types';
import { UserType } from '../../../types/user.type';
import { ChatMessageType } from "../components/room/chat/chat.types";

// Environment variables
import { environment } from '../../../environment';

// Components
import { SocketAvailable } from './socket.available';
import { get } from "http";

// Audio Assets
const messageAudio = require('./message.mp3');

// Socket Status
enum SocketStatus {
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    ERROR_CONNECTING = 'error connecting',
}

// Socket Instance Interface
interface SocketInstanceInterface {
    debug?: boolean;
    roomId: string | undefined;
    userId: string;
    user: UserType;
    userName: string;
    localCall: boolean;
    onLog?: (log: DishLogType) => void;
    reconnectToRoom: (roomId: string) => void;
    onConnect?: () => void;
    onSocketId?: (socketId: string) => void;
    onDisconnect?: () => void;
    onErrorConnecting?: () => void;
    askCloseSession: (ask: boolean) => void;
    onNewLocalStream?: (stream: MediaStream, type?: {type: string}) => void;
    onRemoveLocalStream?: (streamIndex: number) => void;
    onUpdateParticipants?: (participants: SocketParticipants) => void;
    onMessage?: (message: ChatMessageType) => void;
    onRemoveStreamParticipant?: (streamId: string) => void;
    onAvailables: (availables: AvailableType[]) => void
    onRoom: (room: string) => void
    onCall: (call: CallType) => void
    onAccepted: (call: CallType) => void
    onRejected: (call: CallType) => void
    onCanceled: (call: CallType) => void
    closeSession: () => void
    onChangeLayout: (layout: string) => void;
    onRemoveStream: (streamId: string) => void;
    onChangeStreamStatus: (streamId: string, status: boolean, type: string) => void;
    onFileShare: (username: string, file: string, filename: string) => void;
    onChangeStreamTrackStatus: (streamId: string, trackId: string, status: boolean, type: 'audio' | 'video', roomId?: string, streamType?: string) => void;
    onChangeVolume: (volume: number) => void;
    onUpdateLocalStream: (newStream: MediaStreamTrack) => void;
    onNoLongerActive: (socketId: string, username: string) => void;
}

// Socket Participants Type
export type SocketParticipants = {
    [userId: string]: {
        peer: RTCPeerConnection[],
        user: UserType,
        streams: MediaStream[]
    }
}

/**
 * Socket Instance
 * @description Socket to video conference
 * @param {SocketInstanceInterface} properties
 * @returns {SocketInstance}
 */
export class SocketInstance {
    private localMediaStreams: MediaStream[] = [];
    private streamTypes: { [streamId: string]: string } = {};
    private streamManual: { [streamId: string]: string } = {};
    private socket?: Socket;
    private debug: boolean;
    private roomId: string | undefined;
    private userId: string;
    private senders: { [streamId: string]: RTCRtpSender } = {};
    private userName: string;
    private user: UserType;
    private getCandidatesHistory: { [key: string]: boolean } = {}
    private participants: SocketParticipants = {}
    public status: SocketStatus = SocketStatus.DISCONNECTED;
    private localCall: boolean;
    private events: {
        onLog?: (log: DishLogType) => void;
        onConnect?: () => void;
        onSocketId?: (socketId: string) => void;
        onDisconnect?: () => void;
        onErrorConnecting?: () => void;
        onNewLocalStream?: (stream: MediaStream, type?: {type: string}, manual?: {manual_id: string}) => void;
        onUpdateParticipants?: (data: SocketParticipants) => void;
        onMessage?: (message: ChatMessageType) => void;
        onRemoveLocalStream?: (streamIndex: number) => void;
        onRemoveStreamParticipant?: (streamId: string) => void;
        onAvailables: (availables: AvailableType[]) => void
        onRoom: (room: string) => void
        onCall: (call: CallType) => void
        onAccepted: (call: CallType) => void
        onRejected: (call: CallType) => void
        onCanceled: (call: CallType) => void
        closeSession: () => void
        askCloseSession: (ask: boolean) => void;
        reconnectToRoom: (roomId: string) => void;
        onChangeLayout: (layout: string) => void;
        onRemoveStream: (streamId: string) => void;
        onChangeStreamStatus: (streamId: string, status: boolean, type: string) => void;
        onFileShare: (username: string, file: string, filename: string) => void;
        onChangeStreamTrackStatus: (streamId: string, trackId: string, status: boolean, type: 'audio' | 'video', roomId?: string, streamType?: string) => void;
        onChangeVolume: (volume: number) => void;
        onUpdateLocalStream: (stream: MediaStreamTrack) => void;
        onNoLongerActive: (socketId: string, username: string) => void;
    }

    constructor({
        onAvailables,
        onRoom,
        onCall,
        onAccepted,
        onRejected,
        onCanceled,
        closeSession,
        askCloseSession,
        reconnectToRoom,
        onChangeLayout,
        onRemoveStream,
        onChangeStreamStatus,
        onChangeStreamTrackStatus,
        onChangeVolume,
        onFileShare,
        onUpdateLocalStream,
        onNoLongerActive,
        user, debug, roomId, userId, userName, onLog, onRemoveStreamParticipant, onRemoveLocalStream, onConnect, onSocketId, onDisconnect, onErrorConnecting, onNewLocalStream, onUpdateParticipants, onMessage }: SocketInstanceInterface) {

        this.debug = debug || false;
        this.roomId = roomId;
        this.userId = userId;
        this.userName = userName;

        this.user = user;

        this.localCall = localStorage.getItem('localCall') === 'true' ? true : false;

        this.events = {
            onLog,
            reconnectToRoom,
            onConnect,
            onSocketId,
            onDisconnect,
            onErrorConnecting,
            onNewLocalStream,
            onUpdateParticipants,
            closeSession,
            onMessage,
            onRemoveLocalStream,
            onRemoveStreamParticipant,
            onAvailables,
            onRoom,
            onCall,
            onAccepted,
            onRejected,
            onCanceled,
            askCloseSession,
            onChangeLayout,
            onRemoveStream,
            onChangeStreamStatus,
            onFileShare,
            onChangeStreamTrackStatus,
            onChangeVolume,
            onUpdateLocalStream,
            onNoLongerActive
        }
        this.socket = this.connect();

        // events of socket
        this.onConnectFailed()
        this.onConnect()
        this.onDisconnect()
        this.onAllUsers()
        this.getOffer()
        this.onMessage()
        this.onError()
        this.onConnectError()

    }

    async shareLocalStreamsToRemoteUser(peer: RTCPeerConnection, stream: MediaStream, type?: {type: string}, manual?: {manual_id: string}) {
      let typeToCheck = type?.type;

      if (!typeToCheck) {
        typeToCheck = this.streamTypes[stream.id];
      }

      stream.getTracks().forEach((track) => {
        if (track.kind === 'audio' && localStorage.getItem('audio_status') === 'false') {
          track.enabled = false;
        }

        if (track.kind === 'video' && localStorage.getItem('video_status') === 'false') {
          track.enabled = false;
        }

        if (
          typeToCheck === 'share.screen' ||
          typeToCheck === 'media' ||
          typeToCheck === 'med.device'
        ) {
          track.enabled = true;
        }

        const sender = peer.addTrack(track, stream);
        this.senders[track.id] = sender;

        if (track.kind === 'audio' || track.kind === 'video') {
          this.socket?.emit('stream:track:change_status', {
            room: this.roomId,
            streamId: stream.id,
            streamType: this.streamTypes[stream.id],
            manual_id: this.streamManual[stream.id],
            trackId: track.id,
            status: track.enabled,
            type: track.kind,
          });
        }

      });

    }

    async updateLocalStreams(streamIndex: string, newStream: MediaStream) {
      // Get all local streams
      const streams = this.localMediaStreams;

      // Create a map of the new stream tracks to easily find them
      const newTracksMap = new Map<string, MediaStreamTrack>();
      newStream.getTracks().forEach((track) => {
        newTracksMap.set(track.kind, track);
      });

      // Get the stream to update and update the tracks
      const stream = streams.find(s => s.id === streamIndex);

      if (stream) {
        // If the stream has no track, then add the new tracks
        if (stream.getTracks().length === 0) {
          const index = streams.findIndex((streamSelected) => streamSelected.id === streamIndex);

          // Remove the old stream and add the new one
          this.removeLocalStream(index);
          this.addLocalStream(newStream);
        }

        stream.getTracks().forEach((track) => {
          const newTrack = newTracksMap.get(track.kind);
          if (newTrack) {
            stream.removeTrack(track);
            stream.addTrack(newTrack);

            if (newTrack.kind === 'video'){
              this.events.onUpdateLocalStream && this.events.onUpdateLocalStream(newTrack);
            }

            Object.keys(this.participants).forEach((userId) => {

              this.participants[userId].peer.forEach((peer) => {

                peer.getSenders().forEach((sender) => {

                  if (sender.track?.id === track.id || !sender.track) {

                    sender.replaceTrack(newTrack);
                  }

                });

              });
            });
          }
        });
      }
    }

    removeParticipants() {

        Object.keys(this.participants).forEach((userId) => {

            this.participants[userId].peer.forEach((peer) => {

                peer.close();

            });

        });

        this.participants = {}
        this.senders = {}
        this.events?.onUpdateParticipants?.(this.participants);
    }
    emit(event: string, data: any) {

        this.socket?.emit(event, data);
    }
    disconnect() {

        this.socket?.disconnect();

        // close all peer connections
        Object.keys(this.participants).forEach((userId) => {

            this.participants[userId].peer.forEach((peer) => {

                peer.close();

            });

        });

    }
    removeLocalStreams() {
        // remove event
        this.localMediaStreams.forEach((stream, index) => {

            this.events?.onRemoveLocalStream?.(index);

        });
        this.localMediaStreams = []
    }

    async removeShareScreen(stream: MediaStream) {
      // get stream
      const index = this.localMediaStreams.findIndex((streamSelected) => streamSelected.id === stream.id);

      // remove stream
      this.removeLocalStream(index);
    }

    async removeLocalStream(streamIndex: number) {

        // get stream
        const stream = this.localMediaStreams[streamIndex];

        // stop stream
        stream.getTracks().forEach((track) => {

            track.stop();
            // stop rtc
            this.localMediaStreams[streamIndex].removeTrack(track);

        });

        this.events?.onRemoveLocalStream?.(streamIndex);
        this.socket?.emit('stream:remove', {
            room: this.roomId,
            streamId: this.localMediaStreams[streamIndex].id,
        });
        // remove local stream from localMediaStreams array
        this.localMediaStreams.splice(streamIndex, 1);

        // remove stream from all participants
        Object.keys(this.participants).forEach((userId) => {

            this.participants[userId].peer.forEach((peer) => {

                peer.getSenders().forEach((sender) => {

                    if (sender.track?.id === stream.id) {

                        peer.removeTrack(sender);

                    }

                });

            });

        })

    }

    setRoom(newRoom: string | undefined = 'jajaja') {
        //@ts-ignore
        window['EnovaitRoomId'] = newRoom;
        this.roomId = newRoom;
    }

    async requestRecordingName(): Promise<string> {
      const defaultName = 'Grabacion de video';
      let recordingName = defaultName;

      if (!recordingName) {
        recordingName = defaultName;
      }

      recordingName = recordingName.replace(/[^a-zA-Z0-9]/g, '_');

      return `${recordingName}_${new Date().toISOString().replace(/[:.-]/g, '')}`;
    }

    async addLocalStream(stream: MediaStream, type?: {type: string}, manual?: {manual_id: string}) {

        this.localMediaStreams.push(stream);
        this.streamTypes[stream.id] = type?.type || '';
        this.streamManual[stream.id] = manual?.manual_id || '';
        stream && stream?.getVideoTracks()?.[0]?.addEventListener?.('ended', () => {

            // remove stream
            // get index of stream
            const index = this.localMediaStreams?.findIndex((streamSelected) => streamSelected.id === stream.id);

            index > -1 && this.removeLocalStream(index);

        })

        const { group }  = JSON.parse(localStorage.getItem('user') || '{}');

        const recordingEnabled = localStorage.getItem('canRecord') === 'true' ? true : false;

        if (recordingEnabled) {
            let recordingName = '';
            // record:
            const mediaRecorder = new MediaRecorder(stream, {
                audioBitsPerSecond: 128000,
                mimeType: 'video/webm; codecs=vp9',
            });

            // Get first path folder of window
            const randomString = Math.random().toString(36).substring(7);
            mediaRecorder.ondataavailable = async (event: any) => {
                this.localCall = localStorage.getItem('localCall') === 'true' ? true : false;
                // @ts-ignore
                if (event.data && event.data.size > 0 && !this.localCall) {
                    // @ts-ignore
                    if (window['EnovaitRoomId'] && window['EnovaitRoomId'] !== 'jajaja') {
                        if (!recordingName) {
                          recordingName = await this.requestRecordingName();
                        }

                        this.socket && this.socket.emit('media:record', {
                          // @ts-ignore
                          roomId: window['EnovaitRoomId'],
                          cameraId: randomString,
                          group: group || undefined,
                          data: event.data,
                          recordName: recordingName,
                        })
                    }

                }

            };

            // This will trigger a dataavailable event every second.
            mediaRecorder.start(5000);

            // This will trigger a single dataavailable event.
            mediaRecorder.requestData();

            // stop stream detect
            stream.getTracks().forEach((track) => {

                track.addEventListener('ended', () => {
                    mediaRecorder.stop();

                });

            }
            );

        }

        // share local streams to all remote users
        this.events.onNewLocalStream && this.events.onNewLocalStream(stream, type, manual);
        this.participants && Object.keys(this.participants).forEach(async (participantId) => {

            const randomString = Math.random().toString(36).substring(7);
            const peer = this.createPeerConnection(participantId, randomString, stream, type, manual);
            // @ts-ignore
            peer.id = randomString;
            this.participants[participantId].peer.push(peer);
            try {

                // have audio:
                const streamWithAudio = await stream.getAudioTracks().length > 0

                // have video:
                const streamWithVideo = await stream.getVideoTracks().length > 0

                const localSdp = await peer.createOffer({
                    iceRestart: true,
                    offerToReceiveAudio: streamWithAudio,
                    offerToReceiveVideo: streamWithVideo,
                });

                try {

                    await peer.setLocalDescription(new RTCSessionDescription(localSdp));
                    this.socket?.emit('rtcp:offer', {
                        sdp: localSdp,
                        offerSendID: this.socket?.id,
                        peerId: randomString,
                        // random string
                        user: this.user,
                        offerReceiveID: participantId,
                    });

                }
                catch (e) {

                    console.error('Error setLocalDescription', e)

                }

            } catch (e) {

                console.error(e);

            }

        })

    }

    handleErrors(error: string, data: any) {

        this.log(error, data)

    }

    connect() {

        try {

            const socket = io(`${environment.dish.host}`, {
                secure: true,
                autoConnect: true,
                timeout: 30000,
                reconnectionDelay: 3000,
                reconnectionAttempts: 20,
                path: '/socket/socket.io',
                extraHeaders: {
                    'Access-Control-Allow-Origin': '*', // Required for CORS support to work
                }
            });

            if (socket) {

                return socket;

            }

            else {

                this.log('error connecting', false);

            }

        }
        catch (e) {

            console.error(e);
            this.log('error connecting', e, false);

        }

    }

    joinRoom(room?: string) {
        if (room) this.roomId = room;
        try {
            this.socket?.emit('room:join', {
                room: this.roomId,
                user: this.user,
            });

        }
        catch (e) {

            this.log('error room join', e, false);

        }

    }

    onReconnect() {

        this.socket?.on('reconnect_attempt', (attempt: any) => {

            this.log(`reconnect attempt ${attempt}`);

        });

    }

    onError() {

        this.socket?.on('error', (error: any) => {

            this.log('error', error, false);
            console.error(error);

        })

    }

    onConnectFailed() {

        this.socket?.on('connect_failed', (error: any) => {

            this.log('connect failed', error, false);

        })

        // on internet is too low
        this.socket?.on('reconnect_failed', (error: any) => {



        })

    }

    onConnectError() {

        this.socket?.on('connect_error', (error: any) => {

            this.events.onErrorConnecting && this.events.onErrorConnecting();

        })

    }

    onConnect() {

        this.socket?.on('connect', () => {

            // change socket status
            this.status = SocketStatus.CONNECTED;

            // outside event
            this.events.onConnect && this.events.onConnect();

            this.log(`Socket ID [${this.socket?.id}]`);
            this.socket?.id && this.events.onSocketId && this.events.onSocketId(this.socket?.id);



            this.socket && this.events && new SocketAvailable({
                socket: this.socket,
                user: this.user,
                events: {
                    closeSession: this.events.closeSession,
                    onAvailables: this.events.onAvailables,
                    onRoom: this.events.onRoom,
                    onCall: this.events.onCall,
                    onAccepted: this.events.onAccepted,
                    onRejected: this.events.onRejected,
                    onCanceled: this.events.onCanceled,
                    askCloseSession: this.events.askCloseSession,
                    onChangeLayout: this.events.onChangeLayout,
                    onRemoveStream: this.events.onRemoveStream,
                    onChangeStreamStatus: this.events.onChangeStreamStatus,
                    onFileShare: this.events.onFileShare,
                    onChangeStreamTrackStatus: this.events.onChangeStreamTrackStatus,
                    onChangeVolume: this.events.onChangeVolume,
                    onNoLongerActive: this.events.onNoLongerActive
                }
            })

        });

    }

    sendMessage(message: string) {

        this.socket?.emit('chat:message', {
            room: this.roomId,
            message: message,
            name: this.user.name + ' ' + this.user.surnames,
            date: new Date().getTime(),
            senderSocketId: this.socket?.id,
        });

    }

    onMessage() {

        this.socket?.on('chat:message', (data: any) => {
            if (data.senderSocketId !== this.socket?.id) {
                const audio = new Audio(messageAudio);
                audio.volume = 0.3;
                audio.play();
            }

            const message: ChatMessageType = {
                room: data.room,
                message: data.message,
                name: data.name,
                date: data.date,
                socketId: data.senderSocketId,
                type: data.senderSocketId === this.socket?.id ? 'me' : 'other',
            }

            this.events.onMessage && this.events.onMessage(message);

        })

    }

    onDisconnect() {

        this.socket?.on('disconnect', () => {

            // change socket status
            this.status = SocketStatus.DISCONNECTED;

            // outside event
            this.events.onDisconnect && this.events.onDisconnect();

        })

    }

    onAllUsers() {

        this.socket?.on('room:all_users', (allUsers: {
            socketId: string,
            user: UserType
        }[]) => {

            allUsers.forEach(async (user) => {
                // Validate if user is not me
                if (user.user.id === this.user?.id) return;

                // Save user:
                if (!this.participants[user.socketId]) this.participants[user.socketId] = { peer: [], streams: [], user: user.user }
                // update all users
                this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);

                // Send all streams to new user
                this.localMediaStreams && this.localMediaStreams.forEach(async (stream) => {

                    const randomString = Math.random().toString(36).substring(7);
                    const peer = this.createPeerConnection(user.socketId, randomString, stream);
                    // @ts-ignore
                    peer.id = randomString;
                    this.participants[user.socketId].peer.push(peer);

                    try {

                        // have audio:
                        const streamWithAudio = await stream.getAudioTracks().length > 0
                        // have video:
                        const streamWithVideo = await stream.getVideoTracks().length > 0
                        const localSdp = await peer.createOffer({
                            iceRestart: true,
                            offerToReceiveAudio: streamWithAudio,
                            offerToReceiveVideo: streamWithVideo,
                        });

                        await peer.setLocalDescription(new RTCSessionDescription(localSdp));
                        this.socket?.emit('rtcp:offer', {
                            sdp: localSdp,
                            offerSendID: this.socket.id,
                            offerReceiveID: user.socketId,
                            peerId: randomString,

                        });

                    } catch (e) {

                        console.error(e);

                    }

                });

            });

        });
        this.socket?.on('room:new_user', (user: { socketId: string; user: UserType }) => {

            // Validate if user is not me
            if (user.user.id === this.user?.id) return;

            // Save user:
            if (!this.participants[user.socketId]) this.participants[user.socketId] = {
                peer: [], streams: [], user: user.user
            }

            // update all users
            this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);


            this.localMediaStreams && this.localMediaStreams.forEach(async (stream) => {

                const randomString = Math.random().toString(36).substring(7);
                const peer = this.createPeerConnection(user.socketId, randomString, stream);
                // @ts-ignore
                peer.id = randomString;
                this.participants[user.socketId].peer.push(peer);

                try {

                    // have audio:
                    const streamWithAudio = await stream.getAudioTracks().length > 0
                    // have video:
                    const streamWithVideo = await stream.getVideoTracks().length > 0

                    const localSdp = await peer.createOffer({
                        iceRestart: true,
                        offerToReceiveAudio: streamWithAudio,
                        offerToReceiveVideo: streamWithVideo,
                    });

                    await peer.setLocalDescription(new RTCSessionDescription(localSdp));
                    this.socket?.emit('rtcp:offer', {
                        sdp: localSdp,
                        offerSendID: this.socket.id,
                        peerId: randomString,

                        offerReceiveID: user.socketId,
                    });

                } catch (e) {

                    console.error(e);

                }

            });

        });

    }

    getOffer() {

        this.socket?.on(
            'rtcp:offer',
            async (data: {
                sdp: RTCSessionDescription;
                offerSendID: string;
                offerSendEmail: string;
                peerId: string;
                user: UserType
            }) => {

                const { sdp, offerSendID, peerId, } = data;
                // search peerId
                // @ts-ignore

                const peer = this.createPeerConnection(offerSendID, peerId);

                // @ts-ignore
                peer.id = peerId;
                if (!this.participants[offerSendID]) {
                    this.participants[offerSendID] = { peer: [], streams: [], user: data.user }
                }
                this.participants[offerSendID].peer.push(peer);

                try {

                    await peer.setRemoteDescription(new RTCSessionDescription(sdp));
                    const localSdp = await peer.createAnswer();
                    await peer.setLocalDescription(new RTCSessionDescription(localSdp));
                    this.socket?.emit('rtcp:answer', {
                        sdp: localSdp,
                        answerSendID: this.socket.id,
                        peerId: peerId,
                        answerReceiveID: offerSendID,
                    });

                } catch (e) {

                    console.error(e);

                }

            },
        );

        this.socket?.on(
            'rtcp:answer',
            (data: { sdp: RTCSessionDescription; peerId: string; answerSendID: string }) => {

                const { sdp, answerSendID, peerId, } = data;

                this.participants[answerSendID].peer.forEach((peer) => {

                    // @ts-ignore

                    //@ts-ignore
                    if (peer.id === peerId) {

                        if (!peer) return;
                        if (!sdp) return
                        try {

                            peer.setRemoteDescription(new RTCSessionDescription(sdp));

                        }
                        catch (e) {

                            console.error('Error  getanswer')
                            console.error(e)

                        }

                    }

                })

            },
        );

        this.socket?.on(
            'rtcp:candidate',
            async (data: { user: UserType, peerId: string, candidate: RTCIceCandidateInit; candidateSendID: string }) => {

                const { candidate, candidateSendID } = data;

                if (!this.participants[candidateSendID]) {
                    this.participants[candidateSendID] = { peer: [], streams: [], user: data.user }
                }

                this.participants[candidateSendID].peer.forEach((peer) => {

                    try {

                        // @ts-ignore
                        if (peer.id === data.peerId && candidate) peer.addIceCandidate(new RTCIceCandidate(candidate));

                    }
                    catch (e) {

                        console.error(e)

                    }

                })

            },
        );

        this.socket?.on('room:user_left', (data: { socketId: string }) => {

            if (this.participants[data.socketId]) {

                // close peer connection
                this.participants[data.socketId]?.peer?.forEach((peer) => {

                    peer.close();

                });
                delete this.participants[data.socketId];
                this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);

            }
            return

        });

        this.socket?.on('stream:removed_remote', (data: { room: string, streamId: string }) => {

            // map this.participants and remove stream with same id
            Object.keys(this.participants).forEach((key) => {

                this.participants[key].streams = this.participants[key].streams.filter((stream) => stream.id !== data.streamId);

            });

            // search stream delete and update participants
            this.events?.onRemoveStreamParticipant && this.events.onRemoveStreamParticipant(data.streamId);

        });

    }

    createRTC() {

        const rtc: RTCPeerConnection = new RTCPeerConnection(
            {
                // @ts-ignore
                iceServers: [

                    { urls:
                      (!process.env.REACT_APP_ENV ||
                        process.env.REACT_APP_ENV === 'development' ||
                        process.env.REACT_APP_ENV === 'preproduction')
                          ? "stun:telemedicinacomitas.com:3478"
                          : "stun:telemedicinacomitas.com:3478", },
                    { urls:
                      (!process.env.REACT_APP_ENV ||
                        process.env.REACT_APP_ENV === 'development' ||
                        process.env.REACT_APP_ENV === 'preproduction')
                          ? "turn:telemedicinacomitas.com:3478?transport=udp"
                          : "turn:telemedicinacomitas.com:3478?transport=udp", username: 'enovait', credential: 'password' },

                ],
                iceTransportPolicy: 'relay'
            }
        );

        return rtc;

    }

    createPeerConnection(userId: string, randomString: string, streamLocal?: MediaStream, type?: {type: string}, manual?: {manual_id: string}) {
        const pc = this.createRTC();

        pc.onicecandidate = (e) => {

            if (!e.candidate) return;

            this.socket?.emit('rtcp:candidate', {
                candidate: e.candidate,
                candidateSendID: this.socket.id,
                peerId: randomString,
                user: this.user,
                candidateReceiveID: userId,
            });

        };

        pc.onicegatheringstatechange = (e) => {
            // console.log('=============')
            // console.log('ERROR #2:', e)
            // console.log('=============')
        };

        pc.oniceconnectionstatechange = async (e) => {

            const state = pc.signalingState;

            // console.log('=============')
            // console.log('oniceconnectionstatechange:', state)
            // console.log('iceconnectionstatechange:', pc.iceConnectionState)
            // console.log(pc)
            // console.log('=============')

            // disconnect:
            if (pc.iceConnectionState === 'disconnected') {

              pc.close();
              delete this.participants[userId];
              this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);

            }


        };

        pc.ontrack = (e) => {

            // console.log('onbtrack', e)
            // console.log('par', this.participants)
            // console.log('ontrack:', userId)
            // console.log('par came:', this.participants[userId])

            if (e.streams && e.streams.length > 0) {

                e.streams.forEach((stream) => {

                    // detect track is stream exist
                    if (this.participants[userId].streams.find((s) => s.id === stream.id)) return;

                    // add stream to participants
                    this.participants[userId].streams?.push(stream);
                    this.events.onUpdateParticipants && this.events.onUpdateParticipants(this.participants);

                });

            }

        };

        streamLocal && this.shareLocalStreamsToRemoteUser(pc, streamLocal, type, manual);

        // eslint-disable-next-line max-lines
        return pc;

    }

    log(message: any, data?: any, status = true) {

        this.events.onLog && this.events.onLog({ status: status, log: message, date: new Date() });

    }
}
